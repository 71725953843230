import React from "react";
import Select from "react-select";

export const SelectField = ({
  options,
  field,
  form,
  defaultValue,
  className,     
}) => (
  <Select
    options={options}
    name={field.name}
    value={
      options ? options.find((option) => option.value === field.value) : ""
    }
    onChange={(option) => {
      form.setFieldValue(field.name, option.value);
      if (document.querySelector("[name='next']")) {
        document.querySelector("[name='next']").classList.remove("button-disabled");
      }      
    }}
    onBlur={field.onBlur}
    defaultValue={defaultValue}
    className={className}  
    isSearchable={false} 
    placeholder={'Select one'}    
  />
);
