export const baseOptionsData = {
  acf: {
    additional_text: "",
    button: {
      text: "Schedule a Call",
      url: "https://get.formationscorp.com/speak-with-our-experts-schedule-now",
      target: "_blank",
      id: "",
    },
    column_1:
      '<h2>Office</h2>\n<p>2821 Northup Way, Suite 250<br />\nBellevue, WA 98004</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
    column_2:
      '<h2>Contact</h2>\n<p><a href="mailto:team@formationscorp.com">team@formationscorp.com</a><br />\n<a href="tel:1.800.868.1186">1.800.868.1186</a></p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
    contact:
      '<h2>Drop us a line: Don&#8217;t be a stranger.</h2>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
    cs_heading_text:
      '<p>Read our case studies, get to know some of our customers, and learn how Formations helped them thrive!</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
    cs_heading_title: "Formations Case Studies",
    cs_load_more_text: "More Case Studies ",
    cta: {
      title: {
        text: "Save thousands of dollars on taxes, make better financial decisions, and get access to corporate-level benefits.",
        type: "h2",
      },
      additional_text: "",
      image: {
        ID: 222,
        id: 222,
        title: "ico-dots-2",
        filename: "ico-dots-2.svg",
        filesize: 8215,
        url: "assets/uploads/2020/03/ico-dots-2.svg",
        link: "https://formationscorp.com/ico-dots-2/",
        alt: "",
        author: "7",
        description: "",
        caption: "",
        name: "ico-dots-2",
        status: "inherit",
        uploaded_to: 0,
        date: "2020-03-09 20:12:34",
        modified: "2020-03-09 20:15:02",
        menu_order: 0,
        mime_type: "image/svg+xml",
        type: "image",
        subtype: "svg+xml",
        icon: "image/media/default.png",
        width: 141,
        height: 142,
        sizes: {
          thumbnail: "assets/uploads/2020/03/ico-dots-2.svg",
          "thumbnail-width": 141,
          "thumbnail-height": 142,
          medium: "assets/uploads/2020/03/ico-dots-2.svg",
          "medium-width": 141,
          "medium-height": 142,
          medium_large: "assets/uploads/2020/03/ico-dots-2.svg",
          "medium_large-width": 141,
          "medium_large-height": 142,
          large: "assets/uploads/2020/03/ico-dots-2.svg",
          "large-width": 141,
          "large-height": 142,
          "1536x1536": "assets/uploads/2020/03/ico-dots-2.svg",
          "1536x1536-width": 141,
          "1536x1536-height": 142,
          "2048x2048": "assets/uploads/2020/03/ico-dots-2.svg",
          "2048x2048-width": 141,
          "2048x2048-height": 142,
          "footer-cta": "assets/uploads/2020/03/ico-dots-2.svg",
          "footer-cta-width": 141,
          "footer-cta-height": 142,
          "post-tile": "assets/uploads/2020/03/ico-dots-2.svg",
          "post-tile-width": 141,
          "post-tile-height": 142,
          "half-width": "assets/uploads/2020/03/ico-dots-2.svg",
          "half-width-width": 141,
          "half-width-height": 142,
          "team-thumb": "assets/uploads/2020/03/ico-dots-2.svg",
          "team-thumb-width": 141,
          "team-thumb-height": 142,
          "how-icon": "assets/uploads/2020/03/ico-dots-2.svg",
          "how-icon-width": 141,
          "how-icon-height": 142,
          "vp-hero-image": "assets/uploads/2020/03/ico-dots-2.svg",
          "vp-hero-image-width": 141,
          "vp-hero-image-height": 142,
          "vp-cta-image": "assets/uploads/2020/03/ico-dots-2.svg",
          "vp-cta-image-width": 141,
          "vp-cta-image-height": 142,
          icons: "assets/uploads/2020/03/ico-dots-2.svg",
          "icons-width": 141,
          "icons-height": 142,
          "square-big": "assets/uploads/2020/03/ico-dots-2.svg",
          "square-big-width": 141,
          "square-big-height": 142,
          "submenu-img": "assets/uploads/2020/03/ico-dots-2.svg",
          "submenu-img-width": 141,
          "submenu-img-height": 142,
        },
      },
      json: {
        ID: 224,
        id: 224,
        title: "logo-animation",
        filename: "logo-animation.json",
        filesize: 112594,
        url: "assets/uploads/2020/03/logo-animation.json",
        link: "https://formationscorp.com/logo-animation/",
        alt: "",
        author: "7",
        description: "",
        caption: "",
        name: "logo-animation",
        status: "inherit",
        uploaded_to: 0,
        date: "2020-03-09 20:14:47",
        modified: "2020-03-09 20:14:47",
        menu_order: 0,
        mime_type: "application/json",
        type: "application",
        subtype: "json",
        icon: "image/media/default.png",
      },
      button: {
        text: "Schedule a Call",
        url: "https://get.formationscorp.com/speak-with-our-experts-schedule-now",
        target: "_blank",
        id: "",
      },
      section_settings: {
        section_id: "",
        section_class: "",
        hide: false,
        hide_on_mobile: false,
      },
    },
    cta_cpt: {
      title: {
        text: "Save thousands of dollars on taxes, make better financial decisions, and get access to corporate-level benefits.",
        type: "h2",
      },
      additional_text: "",
      image: {
        ID: 222,
        id: 222,
        title: "ico-dots-2",
        filename: "ico-dots-2.svg",
        filesize: 8215,
        url: "assets/uploads/2020/03/ico-dots-2.svg",
        link: "https://formationscorp.com/ico-dots-2/",
        alt: "",
        author: "7",
        description: "",
        caption: "",
        name: "ico-dots-2",
        status: "inherit",
        uploaded_to: 0,
        date: "2020-03-09 20:12:34",
        modified: "2020-03-09 20:15:02",
        menu_order: 0,
        mime_type: "image/svg+xml",
        type: "image",
        subtype: "svg+xml",
        icon: "image/media/default.png",
        width: 141,
        height: 142,
        sizes: {
          thumbnail: "assets/uploads/2020/03/ico-dots-2.svg",
          "thumbnail-width": 141,
          "thumbnail-height": 142,
          medium: "assets/uploads/2020/03/ico-dots-2.svg",
          "medium-width": 141,
          "medium-height": 142,
          medium_large: "assets/uploads/2020/03/ico-dots-2.svg",
          "medium_large-width": 141,
          "medium_large-height": 142,
          large: "assets/uploads/2020/03/ico-dots-2.svg",
          "large-width": 141,
          "large-height": 142,
          "1536x1536": "assets/uploads/2020/03/ico-dots-2.svg",
          "1536x1536-width": 141,
          "1536x1536-height": 142,
          "2048x2048": "assets/uploads/2020/03/ico-dots-2.svg",
          "2048x2048-width": 141,
          "2048x2048-height": 142,
          "footer-cta": "assets/uploads/2020/03/ico-dots-2.svg",
          "footer-cta-width": 141,
          "footer-cta-height": 142,
          "post-tile": "assets/uploads/2020/03/ico-dots-2.svg",
          "post-tile-width": 141,
          "post-tile-height": 142,
          "half-width": "assets/uploads/2020/03/ico-dots-2.svg",
          "half-width-width": 141,
          "half-width-height": 142,
          "team-thumb": "assets/uploads/2020/03/ico-dots-2.svg",
          "team-thumb-width": 141,
          "team-thumb-height": 142,
          "how-icon": "assets/uploads/2020/03/ico-dots-2.svg",
          "how-icon-width": 141,
          "how-icon-height": 142,
          "vp-hero-image": "assets/uploads/2020/03/ico-dots-2.svg",
          "vp-hero-image-width": 141,
          "vp-hero-image-height": 142,
          "vp-cta-image": "assets/uploads/2020/03/ico-dots-2.svg",
          "vp-cta-image-width": 141,
          "vp-cta-image-height": 142,
          icons: "assets/uploads/2020/03/ico-dots-2.svg",
          "icons-width": 141,
          "icons-height": 142,
          "square-big": "assets/uploads/2020/03/ico-dots-2.svg",
          "square-big-width": 141,
          "square-big-height": 142,
          "submenu-img": "assets/uploads/2020/03/ico-dots-2.svg",
          "submenu-img-width": 141,
          "submenu-img-height": 142,
        },
      },
      json: {
        ID: 224,
        id: 224,
        title: "logo-animation",
        filename: "logo-animation.json",
        filesize: 112594,
        url: "assets/uploads/2020/03/logo-animation.json",
        link: "https://formationscorp.com/logo-animation/",
        alt: "",
        author: "7",
        description: "",
        caption: "",
        name: "logo-animation",
        status: "inherit",
        uploaded_to: 0,
        date: "2020-03-09 20:14:47",
        modified: "2020-03-09 20:14:47",
        menu_order: 0,
        mime_type: "application/json",
        type: "application",
        subtype: "json",
        icon: "image/media/default.png",
      },
      button: {
        text: "Learn More",
        url: "#hero-popup-form",
        target: "form-popup",
        id: "",
      },
      section_settings: {
        section_id: "",
        section_class: "",
        hide: false,
        hide_on_mobile: false,
      },
    },
    disclaimer_text:
      '<p>Formations is a new type of financial solution. We automate the creation and ongoing management of S-Corp, the most efficient tax structure for the self-employed, and by doing so, we save thousands of dollars every year on taxes, provide affordable health insurance and other corporate-level benefits, and issue monthly paychecks so our users can enjoy the perks of a predictable income. We believe that being independent shouldn&#8217;t mean being alone, and are on a mission to become the largest employer of self-employed individuals in the US. Join us today to unlock your financial potential.</p>\n<p><a role="menuitem" href="https://formationscorp.com/privacy-policy/" target="_self">Privacy Policy</a> | <a role="menuitem" href="https://formationscorp.com/terms-of-use/" target="_self">Terms of Use</a> | <a role="menuitem" href="https://formationscorp.com/cookie-policy/" target="_self">Cookie Policy</a></p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
    email: "mailto:team@formationscorp.com",
    f_button: "",
    f_img: false,
    facebook: "https://www.facebook.com/Formationscorp/",
    favicon:
      "https://formationscorp.comwp-content/uploads/2020/03/favicon-32x32-1.png",
    footer_copyright_text:
      '<p>All right reserved.</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
    footer_cta_button: "",
    footer_cta_text: "",
    footer_scripts: "",
    form_embed_code: "",
    header_scripts: "",
    image: {
      ID: 222,
      id: 222,
      title: "ico-dots-2",
      filename: "ico-dots-2.svg",
      filesize: 8215,
      url: "assets/uploads/2020/03/ico-dots-2.svg",
      link: "https://formationscorp.com/ico-dots-2/",
      alt: "",
      author: "7",
      description: "",
      caption: "",
      name: "ico-dots-2",
      status: "inherit",
      uploaded_to: 0,
      date: "2020-03-09 20:12:34",
      modified: "2020-03-09 20:15:02",
      menu_order: 0,
      mime_type: "image/svg+xml",
      type: "image",
      subtype: "svg+xml",
      icon: "image/media/default.png",
      width: 141,
      height: 142,
      sizes: {
        thumbnail: "assets/uploads/2020/03/ico-dots-2.svg",
        "thumbnail-width": 141,
        "thumbnail-height": 142,
        medium: "assets/uploads/2020/03/ico-dots-2.svg",
        "medium-width": 141,
        "medium-height": 142,
        medium_large: "assets/uploads/2020/03/ico-dots-2.svg",
        "medium_large-width": 141,
        "medium_large-height": 142,
        large: "assets/uploads/2020/03/ico-dots-2.svg",
        "large-width": 141,
        "large-height": 142,
        "1536x1536": "assets/uploads/2020/03/ico-dots-2.svg",
        "1536x1536-width": 141,
        "1536x1536-height": 142,
        "2048x2048": "assets/uploads/2020/03/ico-dots-2.svg",
        "2048x2048-width": 141,
        "2048x2048-height": 142,
        "footer-cta": "assets/uploads/2020/03/ico-dots-2.svg",
        "footer-cta-width": 141,
        "footer-cta-height": 142,
        "post-tile": "assets/uploads/2020/03/ico-dots-2.svg",
        "post-tile-width": 141,
        "post-tile-height": 142,
        "half-width": "assets/uploads/2020/03/ico-dots-2.svg",
        "half-width-width": 141,
        "half-width-height": 142,
        "team-thumb": "assets/uploads/2020/03/ico-dots-2.svg",
        "team-thumb-width": 141,
        "team-thumb-height": 142,
        "how-icon": "assets/uploads/2020/03/ico-dots-2.svg",
        "how-icon-width": 141,
        "how-icon-height": 142,
        "vp-hero-image": "assets/uploads/2020/03/ico-dots-2.svg",
        "vp-hero-image-width": 141,
        "vp-hero-image-height": 142,
        "vp-cta-image": "assets/uploads/2020/03/ico-dots-2.svg",
        "vp-cta-image-width": 141,
        "vp-cta-image-height": 142,
        icons: "assets/uploads/2020/03/ico-dots-2.svg",
        "icons-width": 141,
        "icons-height": 142,
        "square-big": "assets/uploads/2020/03/ico-dots-2.svg",
        "square-big-width": 141,
        "square-big-height": 142,
        "submenu-img": "assets/uploads/2020/03/ico-dots-2.svg",
        "submenu-img-width": 141,
        "submenu-img-height": 142,
      },
    },
    instagram: "https://www.instagram.com/formationscorp/",
    json: {
      ID: 224,
      id: 224,
      title: "logo-animation",
      filename: "logo-animation.json",
      filesize: 112594,
      url: "assets/uploads/2020/03/logo-animation.json",
      link: "https://formationscorp.com/logo-animation/",
      alt: "",
      author: "7",
      description: "",
      caption: "",
      name: "logo-animation",
      status: "inherit",
      uploaded_to: 0,
      date: "2020-03-09 20:14:47",
      modified: "2020-03-09 20:14:47",
      menu_order: 0,
      mime_type: "application/json",
      type: "application",
      subtype: "json",
      icon: "image/media/default.png",
    },
    linkedin: "https://www.linkedin.com/company/formationscorp/",
    logo: {
      ID: 344,
      id: 344,
      title: "Asset 1@2x",
      filename: "Asset-1@2x.svg",
      filesize: 13169,
      url: "assets/uploads/2020/03/Asset-1@2x.svg",
      link: "https://formationscorp.com/asset-12x/",
      alt: "",
      author: "7",
      description: "",
      caption: "",
      name: "asset-12x",
      status: "inherit",
      uploaded_to: 0,
      date: "2020-03-11 15:44:08",
      modified: "2020-03-11 15:44:08",
      menu_order: 0,
      mime_type: "image/svg+xml",
      type: "image",
      subtype: "svg+xml",
      icon: "image/media/default.png",
      width: 158,
      height: 22,
      sizes: {
        thumbnail: "assets/uploads/2020/03/Asset-1@2x.svg",
        "thumbnail-width": 158,
        "thumbnail-height": 22,
        medium: "assets/uploads/2020/03/Asset-1@2x.svg",
        "medium-width": 158,
        "medium-height": 22,
        medium_large: "assets/uploads/2020/03/Asset-1@2x.svg",
        "medium_large-width": 158,
        "medium_large-height": 22,
        large: "assets/uploads/2020/03/Asset-1@2x.svg",
        "large-width": 158,
        "large-height": 22,
        "1536x1536": "assets/uploads/2020/03/Asset-1@2x.svg",
        "1536x1536-width": 158,
        "1536x1536-height": 22,
        "2048x2048": "assets/uploads/2020/03/Asset-1@2x.svg",
        "2048x2048-width": 158,
        "2048x2048-height": 22,
        "footer-cta": "assets/uploads/2020/03/Asset-1@2x.svg",
        "footer-cta-width": 158,
        "footer-cta-height": 22,
        "post-tile": "assets/uploads/2020/03/Asset-1@2x.svg",
        "post-tile-width": 158,
        "post-tile-height": 22,
        "half-width": "assets/uploads/2020/03/Asset-1@2x.svg",
        "half-width-width": 158,
        "half-width-height": 22,
        "team-thumb": "assets/uploads/2020/03/Asset-1@2x.svg",
        "team-thumb-width": 158,
        "team-thumb-height": 22,
        "how-icon": "assets/uploads/2020/03/Asset-1@2x.svg",
        "how-icon-width": 158,
        "how-icon-height": 22,
        "vp-hero-image": "assets/uploads/2020/03/Asset-1@2x.svg",
        "vp-hero-image-width": 158,
        "vp-hero-image-height": 22,
        "vp-cta-image": "assets/uploads/2020/03/Asset-1@2x.svg",
        "vp-cta-image-width": 158,
        "vp-cta-image-height": 22,
        icons: "assets/uploads/2020/03/Asset-1@2x.svg",
        "icons-width": 158,
        "icons-height": 22,
        "square-big": "assets/uploads/2020/03/Asset-1@2x.svg",
        "square-big-width": 158,
        "square-big-height": 22,
        "submenu-img": "assets/uploads/2020/03/Asset-1@2x.svg",
        "submenu-img-width": 158,
        "submenu-img-height": 22,
      },
    },
    logo_dark: {
      ID: 354,
      id: 354,
      title: "Group 4",
      filename: "Group-4.svg",
      filesize: 17177,
      url: "https://assets.formationscorp.com/FormationsLogo.png",
      link: "https://formationscorp.com/group-4/",
      alt: "",
      author: "7",
      description: "",
      caption: "",
      name: "group-4",
      status: "inherit",
      uploaded_to: 0,
      date: "2020-03-11 20:07:55",
      modified: "2020-03-11 20:07:55",
      menu_order: 0,
      mime_type: "image/svg+xml",
      type: "image",
      subtype: "svg+xml",
      icon: "image/media/default.png",
      width: 170,
      height: 25,
      sizes: {
        thumbnail: "assets/uploads/2020/03/Group-4.svg",
        "thumbnail-width": 170,
        "thumbnail-height": 25,
        medium: "assets/uploads/2020/03/Group-4.svg",
        "medium-width": 170,
        "medium-height": 25,
        medium_large: "assets/uploads/2020/03/Group-4.svg",
        "medium_large-width": 170,
        "medium_large-height": 25,
        large: "assets/uploads/2020/03/Group-4.svg",
        "large-width": 170,
        "large-height": 25,
        "1536x1536": "assets/uploads/2020/03/Group-4.svg",
        "1536x1536-width": 170,
        "1536x1536-height": 25,
        "2048x2048": "assets/uploads/2020/03/Group-4.svg",
        "2048x2048-width": 170,
        "2048x2048-height": 25,
        "footer-cta": "assets/uploads/2020/03/Group-4.svg",
        "footer-cta-width": 170,
        "footer-cta-height": 25,
        "post-tile": "assets/uploads/2020/03/Group-4.svg",
        "post-tile-width": 170,
        "post-tile-height": 25,
        "half-width": "assets/uploads/2020/03/Group-4.svg",
        "half-width-width": 170,
        "half-width-height": 25,
        "team-thumb": "assets/uploads/2020/03/Group-4.svg",
        "team-thumb-width": 170,
        "team-thumb-height": 25,
        "how-icon": "assets/uploads/2020/03/Group-4.svg",
        "how-icon-width": 170,
        "how-icon-height": 25,
        "vp-hero-image": "assets/uploads/2020/03/Group-4.svg",
        "vp-hero-image-width": 170,
        "vp-hero-image-height": 25,
        "vp-cta-image": "assets/uploads/2020/03/Group-4.svg",
        "vp-cta-image-width": 170,
        "vp-cta-image-height": 25,
        icons: "assets/uploads/2020/03/Group-4.svg",
        "icons-width": 170,
        "icons-height": 25,
        "square-big": "assets/uploads/2020/03/Group-4.svg",
        "square-big-width": 170,
        "square-big-height": 25,
        "submenu-img": "assets/uploads/2020/03/Group-4.svg",
        "submenu-img-width": 170,
        "submenu-img-height": 25,
      },
    },
    popup_form: {
      id: "hero-popup-form",
      code: '<h2 class="section-title">Please fill in the form</h2>\r\n<!--[if lte IE 8]>\r\n                <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>\r\n                <![endif]-->\r\n                <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>\r\n<script>\r\n        hbspt.forms.create({\r\n        portalId: "6637631",\r\n        formId: "aff012b5-92cb-4562-9c1b-b90ee622018e"\r\n        });\r\n    </script> ',
    },
    section_settings: {
      section_id: "",
      section_class: "",
      hide: false,
      hide_on_mobile: false,
    },
    summary: [
      {
        summary_text:
          '<p><strong>Reduce personal risk.</strong> Over 60% of full-time self-employed individuals in the US are unincorporated. By incorporating, you are not personally liable for the company&#8217;s debts or liabilities.</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
      },
      {
        summary_text:
          '<p><strong>Save on taxes</strong>. Your taxes are the biggest unmanaged expense of your business. But they CAN BE managed. On average, self-employed individuals are overpaying about $8,400 a year in federal and state taxes.</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
      },
      {
        summary_text:
          '<p><strong>Save time</strong>. With modern cloud accounting and good configuration, mundane, repeated work can be saved so you can spend that time on what’s important to you.</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
      },
      {
        summary_text:
          '<p><strong>Peace of mind</strong>. Compliance should be top of mind, and Schedule C (the tax form for sole-proprietorship) is the most audited form by the IRS.  Moving to an S-Corp, improves your business compliance and reduces your chances of audit by thousands of percent.</p>\n<p><input id="hippowiz-ass-injected" type="hidden" value="true" /><input id="hvmessage-toextension-listener" type="hidden" value="none" /></p>\n',
      },
    ],
    summary_title: "KEY BENEFITS OF OPTIMIZING YOUR BUSINESS STRUCTURE",
    title: {
      text: "Save thousands of dollars on taxes, make better financial decisions, and get access to corporate-level benefits.",
      type: "h2",
    },
    twitter: "https://twitter.com/formationscorp",
    youtube: "https://www.youtube.com/channel/UCdIoM-lbA5i274JP8iIt7Og",
  },
};
