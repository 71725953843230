import React from "react";
import Cookies from "universal-cookie";

import sprite from "../images/svg/sprite.svg";
import ReportPrint from "./print";
import { ReportBlock } from "./assets/ReportBlock";
import { exportComponentAsPDF } from "../services/react-component-export-image";
import { ResultBlock } from "./assets/ResultBlock";
import { ResultHeader } from "./assets/ResultHeader";
import { isEmbedCalculator } from "../helpers/queryParam";

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();

    const cookies = new Cookies();
    this.formData = cookies.get("formationsCalculator");
  }

  render() {
    const isEmbed = isEmbedCalculator();
    return (
      <div className={isEmbed ? "full-container-report": "container"}>
      <div className={`${isEmbed ? 'full-main'  : 'main'} report`}>
          <div className="report__header">
            <ResultHeader />
            <div className="report__header-icons">
              <div
                className="report__icon"
                onClick={() =>
                  exportComponentAsPDF(this.componentRef, "report.pdf")
                }
              >
                <svg className="fileDownload-icon">
                  <use xlinkHref={sprite + "#fileDownload"}></use>
                </svg>
              </div>
              <div className="report__icon">
                <svg className="share-icon">
                  <use xlinkHref={sprite + "#share"}></use>
                </svg>
              </div>
              <div
                className="report__icon"
                onClick={() => {
                  window.print();
                }}
              >
                <svg className="print-icon">
                  <use xlinkHref={sprite + "#print"}></use>
                </svg>
              </div>
            </div>
          </div>
          <div className="row sidebar__sticky">
            <div className="content" ref={this.componentRef}>
              <ResultBlock id="form_open" />
              <div className="result__sidebar">
                <ReportBlock />
              </div>
              <ReportPrint id="form_complete" />
            </div>
            <aside className="sidebar sidebar_report">
              <div className="sidebar__inner">
                <ReportBlock />
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
