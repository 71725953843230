import React, { useEffect, useState } from "react";
import wordpressService from "../../services/wordpressService";

export const Variants = () => {
  const [resultData, setReportData] = useState({
    acf: {
      sidebar: {
        sidebar_title: "",
        sidebar_items: [
          {
            text: "",
            button_text: "",
            button_link: "",
          },
          {
            text: "",
            button_text: "",
            button_link: "",
          },
        ],
      },
    },
  });

  useEffect(() => {
    (async () => {
      setReportData(await wordpressService.getData("result"));
    })();
  }, []);

  return (
    <>
      <div className="sidebar__report-block-text">
        <h3 className="sidebar__title sidebar__report-title">
          {resultData.acf.sidebar.sidebar_title}
        </h3>
        <div dangerouslySetInnerHTML={{__html: resultData.acf.sidebar.sidebar_items[0].text}} />
        <div className="sidebar__button">
          <a
            href={resultData.acf.sidebar.sidebar_items[0].button_link}
            className="button button-yellow sidebar__button-send"
            target="_blank"
            rel="noopener noreferrer"
          >
            {resultData.acf.sidebar.sidebar_items[0].button_text}
          </a>
        </div>
      </div>   
    </>
  );
};
