export const baseFormData = {
  acf: {
    calculator_form_image: {
      ID: 2040,
      id: 2040,
      title: "4",
      filename: "4.png",
      filesize: 4454,
      url: "assets/uploads/2020/11/4.png",
      link: "https://get.formationscorp.com/apply-to-join",
      alt: "",
      author: "7",
      description: "",
      caption: "",
      name: "4",
      status: "inherit",
      uploaded_to: 2072,
      date: "2020-11-03 11:44:09",
      modified: "2021-01-15 23:03:31",
      menu_order: 0,
      mime_type: "image/png",
      type: "image",
      subtype: "png",
      icon: "image/media/default.png",
      width: 322,
      height: 296,
      sizes: {
        thumbnail: "assets/uploads/2020/11/4-150x150.png",
        "thumbnail-width": 150,
        "thumbnail-height": 150,
        medium: "assets/uploads/2020/11/4-300x276.png",
        "medium-width": 300,
        "medium-height": 276,
        medium_large: "assets/uploads/2020/11/4.png",
        "medium_large-width": 322,
        "medium_large-height": 296,
        large: "assets/uploads/2020/11/4.png",
        "large-width": 322,
        "large-height": 296,
        "1536x1536": "assets/uploads/2020/11/4.png",
        "1536x1536-width": 322,
        "1536x1536-height": 296,
        "2048x2048": "assets/uploads/2020/11/4.png",
        "2048x2048-width": 322,
        "2048x2048-height": 296,
        "footer-cta": "assets/uploads/2020/11/4.png",
        "footer-cta-width": 192,
        "footer-cta-height": 176,
        "post-tile": "assets/uploads/2020/11/4-322x276.png",
        "post-tile-width": 322,
        "post-tile-height": 276,
        "half-width": "assets/uploads/2020/11/4.png",
        "half-width-width": 322,
        "half-width-height": 296,
        "team-thumb": "assets/uploads/2020/11/4-170x170.png",
        "team-thumb-width": 170,
        "team-thumb-height": 170,
        "how-icon": "assets/uploads/2020/11/4-150x138.png",
        "how-icon-width": 150,
        "how-icon-height": 138,
        "vp-hero-image": "assets/uploads/2020/11/4.png",
        "vp-hero-image-width": 322,
        "vp-hero-image-height": 296,
        "vp-cta-image": "assets/uploads/2020/11/4.png",
        "vp-cta-image-width": 322,
        "vp-cta-image-height": 296,
        icons: "assets/uploads/2020/11/4-50x46.png",
        "icons-width": 50,
        "icons-height": 46,
        "square-big": "assets/uploads/2020/11/4.png",
        "square-big-width": 322,
        "square-big-height": 296,
        "submenu-img": "assets/uploads/2020/11/4.png",
        "submenu-img-width": 175,
        "submenu-img-height": 161,
      },
    },
  },
};
