import React from "react";
import "reactjs-popup/dist/index.css";

import { Variants } from "./assets/Variants";
import { ResultBlock } from "./assets/ResultBlock";
import { ResultHeader } from "./assets/ResultHeader";
import { isEmbedCalculator } from "../helpers/queryParam";

export default () => {
  const isEmbed = isEmbedCalculator();
  return (
    <div className={isEmbed ? "full-container full-report": "container"}>
    <div className={`${isEmbed ? "full-main": "main"} result`}>
        <div className="result__header">
          <ResultHeader />        
        </div>

        <div className="row">
          <div className="content">
            <ResultBlock />
            <div className="result__sidebar">
              <Variants />
            </div>          
          </div>
          <aside className="sidebar sidebar_result">
            <div className="sidebar__inner">
              <Variants />
            </div>
          </aside>
        </div>
      </div>
    </div>    
  );
};
