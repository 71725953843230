import Cookies from "universal-cookie";

class AnalysisService {
  analysis(reportData) {
    function addSavingsCalculation(num) {
      return (savingsCalculation += num);
    }

    function addValue(num) {
      return (point += num);
    }

    function removeValue(num) {
      return (point -= num);
    }

    function addRec(props) {
      const { title, text, icons, count, benefits, note, link, url } = props;
      recommendation.push({
        title: title,
        text: text,
        icons: icons,
        count: count,
        benefits: benefits,
        note: note,
        link: link,
        url: url,
      });
    }

    let point = 0;
    let savingsCalculation = 0;
    let savingsCalculationDiapason = "";
    let savingsCalculationEnd = 0;
    let businessExpensesCalculation = true;

    let recommendation = [];
    let title = "";
    let text = "";
    let count = "";
    let icons = "";
    let benefits = "";
    let note = "";
    let link = "";
    let url = "";

    const cookies = new Cookies();
    const cookiesRes = cookies.get("formationsCalculator");

    if (
      cookiesRes === undefined ||
      cookiesRes["legal-entity"].toString() === ""
    ) {
      return { point, recommendation, redirect: true };
    }

    let annualIncome = cookiesRes["annual-income"];
    let spendTime = cookiesRes["spend-time"];
    let managementCosts = cookiesRes["management-costs"];
    let businessExpenses = cookiesRes["business-expenses"];
    let valueInsurance = cookiesRes["value-insurance"];
    let taxes = cookiesRes["taxes"];

    if (spendTime === 10) {
      addValue(12);
    } else if (spendTime > 10 && spendTime <= 20) {
      addValue(7);
      addSavingsCalculation(1800);
    } else if (spendTime > 20 && spendTime < 40) {
      addValue(0);
      addSavingsCalculation(3600);
    } else if (spendTime >= 40) {
      removeValue(3);
      addSavingsCalculation(5400);
    }

    if (managementCosts >= 0 && managementCosts < 1250) {
      addValue(5);
    } else if (managementCosts >= 1250 && managementCosts < 2500) {
      addValue(7);
    } else if (managementCosts >= 2500 && managementCosts < 3750) {
      addValue(10);
    } else if (managementCosts >= 3750 && managementCosts < 6250) {
      addValue(12);
    } else if (managementCosts >= 6250 && managementCosts < 7500) {
      addValue(0);
      addSavingsCalculation(2000);
    } else if (managementCosts >= 7500) {
      removeValue(3);
      addSavingsCalculation(3000);
    }

    if (annualIncome === 40000) {
      addValue(3);
      if (businessExpenses === 10000) {
        addValue(12);
      }
    } else if (annualIncome > 40000 && annualIncome < 80000) {
      addValue(5);
      if (businessExpenses >= 19000 && businessExpenses < 30000) {
        addValue(12);
      } else if (
        businessExpenses === 10000 ||
        (businessExpenses >= 30000 && businessExpenses < 40000)
      ) {
        addValue(7);
      }
    } else if (annualIncome >= 80000 && annualIncome < 150000) {
      addValue(7);
      if (businessExpenses >= 30000 && businessExpenses < 50000) {
        addValue(12);
      } else if (
        (businessExpenses >= 20000 && businessExpenses < 30000) ||
        (businessExpenses >= 50000 && businessExpenses < 60000)
      ) {
        addValue(7);
      }
    } else if (annualIncome >= 150000) {
      addValue(12);
      if (businessExpenses >= 40000 && businessExpenses < 70000) {
        addValue(12);
      }
    }

    if (taxes > 0) {
      addValue(12);
    }

    cookiesRes["benefits"].forEach(function (item) {
      switch (item) {
        case "Retirement savings (IRA, 401K, etc.)":
          addValue(5);
          break;
        case "Flexible Savings Account (FSA)":
        case "Health Savings Account (HSA)":
          addValue(2);
          break;
        case "Retirement savings (IRA, 401K, etc.)" &&
          "Flexible Savings Account (FSA)" &&
          "Health Savings Account (HSA)":
          addValue(3);
          break;
        default:
          break;
      }
    });

    if (cookiesRes["medical-benefits"].length !== 0) {
      cookiesRes["medical-benefits"].forEach(function (item = []) {
        switch (item) {
          case "Health":
            addValue(7);
            break;
          case "Dental":
            addValue(3);
            break;
          case "Vision":
            addValue(2);
            break;
          default:
            break;
        }
      });
    }

    if (valueInsurance) {
      addValue(10);
    }

    if (cookiesRes["legal-entity"] !== "S-Corp" && annualIncome > 40000) {
      title = reportData.acf.recomendations[0].title;
      text = reportData.acf.recomendations[0].text;
      icons = reportData.acf.recomendations[0].image;
      benefits = reportData.acf.recomendations[0].benefit_title;
      note = reportData.acf.recomendations[0].benefit_text;
      if (annualIncome === 40000) {
        count = "$0";
      } else if (annualIncome > 40000 && annualIncome < 80000) {
        count = "$4,463 - $7,142";
      } else if (annualIncome >= 80000 && annualIncome < 150000) {
        count = "$7,142 - $13,302";
      } else if (annualIncome >= 150000) {
        count = "$13,289 - $14,507";
      }
      addRec({ title, text, icons, count, benefits, note, link: "", url: "" });
    }

    let legalEntity = {
      "S-Corp": {
        points: 12,
      },
      LLC: {
        points: 7,
      },
      "C-Corp": {
        points: 5,
      },
      "Sole proprietorship": {
        points: 0,
      },
      "": {
        points: 0,
      },
    };

    if (cookiesRes["legal-entity"] === "Sole proprietorship") {
      title = reportData.acf.recomendations[1].title;
      text = reportData.acf.recomendations[1].text;
      icons = reportData.acf.recomendations[1].image;
      benefits = reportData.acf.recomendations[1].benefit_title;
      note = reportData.acf.recomendations[1].benefit_text;
      link = "You can start here.";
      url = "#";
      count = "";
      addRec({ title, text, icons, count, benefits, note, link, url });
    }
    addValue(legalEntity[cookiesRes["legal-entity"].toString()].points);

    if (point > 70 && point < 90) {
      title = reportData.acf.recomendations[2].title;
      text = reportData.acf.recomendations[2].text;
      icons = reportData.acf.recomendations[2].image;
      benefits = reportData.acf.recomendations[2].benefit_title;
      note = reportData.acf.recomendations[2].benefit_text;
      link = "";
      url = "";
      count = "";
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (annualIncome === 40000) {
      if (businessExpenses === 10000) {
        businessExpensesCalculation = false;
      }
    } else if (annualIncome > 40000 && annualIncome < 80000) {
      if (businessExpenses >= 19000 && businessExpenses < 30000) {
        businessExpensesCalculation = false;
      } else if (
        businessExpenses === 10000 ||
        (businessExpenses >= 30000 && businessExpenses < 40000)
      ) {
        title = reportData.acf.recomendations[3].title;
        text = reportData.acf.recomendations[3].text;
        icons = reportData.acf.recomendations[3].image;
        benefits = reportData.acf.recomendations[3].benefit_title;
        note = reportData.acf.recomendations[3].benefit_text;
        link = "";
        url = "";
        count = reportData.acf.recomendations[3].benefit_amount;
        addRec({ title, text, icons, count, benefits, note, link, url });
      }
    } else if (annualIncome >= 80000 && annualIncome < 150000) {
      if (businessExpenses >= 30000 && businessExpenses < 50000) {
        businessExpensesCalculation = false;
      } else if (
        (businessExpenses >= 20000 && businessExpenses < 30000) ||
        (businessExpenses >= 50000 && businessExpenses < 60000)
      ) {
        title = reportData.acf.recomendations[3].title;
        text = reportData.acf.recomendations[3].text;
        icons = reportData.acf.recomendations[3].image;
        benefits = reportData.acf.recomendations[3].benefit_title;
        note = reportData.acf.recomendations[3].benefit_text;
        link = "";
        url = "";
        count = reportData.acf.recomendations[3].benefit_amount;
        addRec({ title, text, icons, count, benefits, note, link, url });
      }
    } else if (annualIncome >= 150000) {
      if (businessExpenses >= 40000 && businessExpenses < 70000) {
        businessExpensesCalculation = false;
      }
    }

    if (spendTime > 10) {
      if (spendTime > 10 && spendTime <= 20) {
        count = "$1,800";
      } else if (spendTime > 20 && spendTime < 40) {
        count = "$3,600";
      } else if (spendTime >= 40) {
        count = "$5,400";
      }

      title = reportData.acf.recomendations[4].title;
      text = reportData.acf.recomendations[4].text;
      icons = reportData.acf.recomendations[4].image;
      benefits = reportData.acf.recomendations[4].benefit_title;
      note = reportData.acf.recomendations[4].benefit_text;
      link = "";
      url = "";
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (point > 40 && point <= 70 && cookiesRes["annual-income"] > 40000) {
      title = reportData.acf.recomendations[5].title;
      text = reportData.acf.recomendations[5].text;
      icons = reportData.acf.recomendations[5].image;
      benefits = "";
      note = "";
      link = "";
      url = "";
      count = "";
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (
      cookiesRes["benefits"].indexOf("Retirement savings (IRA, 401K, etc.)") ===
      -1
    ) {
      title = reportData.acf.recomendations[6].title;
      text = reportData.acf.recomendations[6].text;
      icons = reportData.acf.recomendations[6].image;
      benefits = reportData.acf.recomendations[6].benefit_title;
      note = reportData.acf.recomendations[6].benefit_text;
      link = "";
      url = "";
      if (annualIncome === 40000) {
        count = "$0";
      } else if (annualIncome > 40000 && annualIncome < 80000) {
        count = "$7,800";
        addSavingsCalculation(7800);
      } else if (annualIncome >= 80000 && annualIncome < 150000) {
        count = "$9,600";
        addSavingsCalculation(9600);
      } else if (annualIncome >= 150000) {
        count = "$22,750";
        addSavingsCalculation(22750);
      }
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (managementCosts >= 0 && managementCosts < 3000) {
      title = reportData.acf.recomendations[7].title;
      text = reportData.acf.recomendations[7].text;
      icons = reportData.acf.recomendations[7].image;
      benefits = reportData.acf.recomendations[7].benefit_title;
      note = reportData.acf.recomendations[7].benefit_text;
      link = "";
      url = "";
      count = "";
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (managementCosts >= 6250 && managementCosts <= 7500) {
      title = reportData.acf.recomendations[8].title;
      text = reportData.acf.recomendations[8].text;
      icons = reportData.acf.recomendations[8].image;
      benefits = reportData.acf.recomendations[8].benefit_title;
      note = reportData.acf.recomendations[8].benefit_text;
      link = "";
      url = "";
      if (managementCosts >= 5000 && managementCosts <= 7000) {
        count = "$2,000";
      } else if (managementCosts > 7000) {
        count = "$3,000";
      }
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (
      valueInsurance === false &&
      cookiesRes["medical-benefits"].length === 0
    ) {
      title = reportData.acf.recomendations[9].title;
      text = reportData.acf.recomendations[9].text;
      icons = reportData.acf.recomendations[9].image;
      benefits = reportData.acf.recomendations[9].benefit_title;
      note = reportData.acf.recomendations[9].benefit_text;
      link = "";
      url = "";
      count = "";
      addRec({ title, text, icons, count, benefits, note, link, url });
    }

    if (
      cookiesRes["benefits"].indexOf("Flexible Savings Account (FSA)") === -1
    ) {
      title = reportData.acf.recomendations[10].title;
      text = reportData.acf.recomendations[10].text;
      icons = reportData.acf.recomendations[10].image;
      benefits = reportData.acf.recomendations[10].benefit_title;
      note = reportData.acf.recomendations[10].benefit_text;
      link = "";
      url = "";
      count = reportData.acf.recomendations[10].benefit_amount;
      addRec({ title, text, icons, count, benefits, note, link, url });

      if (annualIncome > 40000) {
        addSavingsCalculation(1500);
      }
    }

    if (cookiesRes["benefits"].indexOf("Health Savings Account (HSA)") === -1) {
      title = reportData.acf.recomendations[11].title;
      text = reportData.acf.recomendations[11].text;
      icons = reportData.acf.recomendations[11].image;
      benefits = reportData.acf.recomendations[11].benefit_title;
      note = reportData.acf.recomendations[11].benefit_text;
      link = "";
      url = "";
      count = reportData.acf.recomendations[11].benefit_amount;
      addRec({ title, text, icons, count, benefits, note, link, url });

      if (annualIncome > 40000) {
        addSavingsCalculation(1050);
      }
    }

    if (annualIncome <= 40000) {
      if (businessExpensesCalculation === false) {
        savingsCalculationDiapason = savingsCalculation;
        savingsCalculationEnd = savingsCalculation;
      } else {
        savingsCalculationDiapason = `$${savingsCalculation + 2000} - $${
          savingsCalculation + 5000
        }`;
        savingsCalculationEnd = 5000 + savingsCalculation;
      }
    } else if (annualIncome > 40000 && annualIncome < 80000) {
      if (businessExpensesCalculation === false) {
        savingsCalculationDiapason = `$${savingsCalculation + 4463} - $${
          savingsCalculation + 7142
        }`;
        savingsCalculationEnd = 7142 + savingsCalculation;
      } else {
        savingsCalculationDiapason = `$${savingsCalculation + 4463 + 2000} - $${
          savingsCalculation + 7142 + 5000
        }`;
        savingsCalculationEnd = 7142 + 5000 + savingsCalculation;
      }
    } else if (annualIncome >= 80000 && annualIncome < 150000) {
      if (businessExpensesCalculation === false) {
        savingsCalculationDiapason = `$${savingsCalculation + 7142} - $${
          savingsCalculation + 13302
        }`;
        savingsCalculationEnd = 13302 + savingsCalculation;
      } else {
        savingsCalculationDiapason = `$${savingsCalculation + 7142 + 2000} - $${
          savingsCalculation + 13302 + 5000
        }`;
        savingsCalculationEnd = 13302 + 5000 + savingsCalculation;
      }
    } else if (annualIncome >= 150000) {
      if (businessExpensesCalculation === false) {
        savingsCalculationDiapason = `$${savingsCalculation + 13302} - $${
          savingsCalculation + 14507
        }`;
        savingsCalculationEnd = 14507 + savingsCalculation;
      } else {
        savingsCalculationDiapason = `$${
          savingsCalculation + 13302 + 2000
        } - $${savingsCalculation + 14507 + 5000}`;
        savingsCalculationEnd = 14507 + 5000 + savingsCalculation;
      }
    }

    return {
      point,
      recommendation,
      savingsCalculationEnd,
      savingsCalculationDiapason,
      redirect: false,
    };
  }
}

export default new AnalysisService();
