import { baseStepsData } from "../data/baseStepsData";
import { baseResultsData } from "../data/baseResultsData";
import { baseFormData } from "../data/baseFormData";
import { baseReportData } from "../data/baseReportData";
import { baseOptionsData } from "../data/baseOptionsData";
import { baseMenuData } from "../data/baseMenuData";
import { baseHomeData } from "../data/baseHomeData";

class WordpressService {
  constructor() {
    this.getPageCache = {};
  }

  async getData(dataType) {
    if (typeof this.getPageCache[dataType] !== "undefined") {
      return this.getPageCache[dataType];
    } else {
      let data;

      switch (dataType) {
        case "steps":
          data = baseStepsData;
          break;
        case "result":
          data = baseResultsData;
          break;
        case "form":
          data = baseFormData;
          break;
        case "options":
          data = baseOptionsData;
          break;
        case "menu":
          data = baseMenuData;
          break;  
        case "home":
          data = baseHomeData;
          break;
        case "report":
          data = baseReportData;
          break;      
        default:
          throw Error("Should never happen");
      }

      this.getPageCache[dataType] = data;

      return data;
    }
  }

  async buildCache() {
    this.getPageCache["steps"] = await fetch(this.steps).then((res) =>
      res.json()
    );
    this.getPageCache["result"] = await fetch(this.result).then((res) =>
      res.json()
    );
    this.getPageCache["form"] = await fetch(this.form).then((res) =>
      res.json()
    );
    this.getPageCache["options"] = await fetch(this.options).then((res) =>
      res.json()
    );
    this.getPageCache["menu"] = await fetch(this.menu).then((res) =>
      res.json()
    );    
    this.getPageCache["report"] = await fetch(this.report).then((res) =>
      res.json()
    );
    this.getPageCache["home"] = await fetch(this.home).then((res) =>
      res.json()
    );
  }
}

export default new WordpressService();
