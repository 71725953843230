import React, { useEffect, useState } from "react";
import { Field } from "formik";

import wordpressService from "../../services/wordpressService";
import { baseStepsData } from "../../data/baseStepsData";
import icon from "../../images/icon.png";
import Popup from "reactjs-popup";

export default () => {
  const [stepsData, setStepsData] = useState(baseStepsData);

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("steps"));      
    })();
  }, []);   

  const SidebarExpertTip = () => {
    if (stepsData.acf.step[0].sidebar.sidebar_expert_tip) {
      return <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[0].sidebar.sidebar_expert_tip}} />;
    } else {
      return null;
    }
  };

  const SidebarLink = () => {
    if (stepsData.acf.step[0].sidebar.sidebar_link.title) {
      return (
        <a
          href={stepsData.acf.step[0].sidebar.sidebar_link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar__link"
        >
          {stepsData.acf.step[0].sidebar.sidebar_link.title}
        </a>
      );
    } else {
      return null;
    }
  };

  const DisabledBtn = () => {
    if (document.getElementById("myRadio1").checked ||
        document.getElementById("myRadio2").checked ||
        document.getElementById("myRadio3").checked ||
        document.getElementById("myRadio4").checked) 
    {
      document.querySelector("[name='next']").classList.remove("button-disabled");
    } 
  };  

  if (document.getElementById("myRadio1")) {
    if (document.getElementById("myRadio1").checked ||
        document.getElementById("myRadio2").checked ||
        document.getElementById("myRadio3").checked ||
        document.getElementById("myRadio4").checked) 
    {
      document.querySelector("[name='next']").classList.remove("button-disabled");  
    } else {
      document.querySelector("[name='next']").classList.add("button-disabled"); 
    }
  }  

  return (     
    <div className="text">
      <h2 className="text__title">What is your business entity?</h2>
      <Popup
        trigger={
          <div className="text__link text__link-mobile">
            <img src={icon} alt="sidebar" />
            What does this mean?
          </div>
        }            
        modal
        className="sidebar__popup"
      >
        {(close) => (
          <div className="sidebar__inner sidebar__inner-mobile">
            <div className="sidebar__close" onClick={close}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#404040" fillOpacity="0.87"/>
              </svg>
            </div>
            <div className="sidebar__block-text">
              <h3 className="sidebar__title">
                {stepsData.acf.step[0].sidebar.sidebar_title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[0].sidebar.sidebar_description}} />
            </div>
            <div className="sidebar__block-link">
              <div className="sidebar__subtitle">
                {stepsData.acf.step[0].sidebar.sidebar_title_2}
              </div>
              <SidebarExpertTip />
              <SidebarLink />
            </div>
          </div>
        )}
      </Popup>          
      <div className="row">
        <div className="text__inner">
          <div className="row">
            <div className="text__checkbox">
              <Field
                type="radio"
                id="myRadio1"
                name="legal-entity"
                value="Sole proprietorship"
                onClick={DisabledBtn}
              />
              <label htmlFor="myRadio1">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[0].tiles[0].tile_icon}
                    alt={stepsData.acf.step[0].tiles[0].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[0].tiles[0].tile_icon_active}
                    alt={stepsData.acf.step[0].tiles[0].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[0].tiles[0].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[0].tiles[0].tile_description}
                  </span>
                </div>
              </label>
            </div>
            <div className="text__checkbox">
              <Field
                type="radio"
                id="myRadio2"
                name="legal-entity"
                value="LLC"
                onClick={DisabledBtn}
              />
              <label htmlFor="myRadio2">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[0].tiles[1].tile_icon}
                    alt={stepsData.acf.step[0].tiles[1].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[0].tiles[1].tile_icon_active}
                    alt={stepsData.acf.step[0].tiles[1].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[0].tiles[1].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[0].tiles[1].tile_description}
                  </span>
                </div>
              </label>
            </div>
            <div className="text__checkbox">
              <Field
                type="radio"
                id="myRadio3"
                name="legal-entity"
                value="S-Corp"
                onClick={DisabledBtn}
              />
              <label htmlFor="myRadio3">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[0].tiles[2].tile_icon}
                    alt={stepsData.acf.step[0].tiles[2].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[0].tiles[2].tile_icon_active}
                    alt={stepsData.acf.step[0].tiles[2].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[0].tiles[2].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[0].tiles[2].tile_description}
                  </span>
                </div>
              </label>
            </div>
            <div className="text__checkbox">
              <Field
                type="radio"
                id="myRadio4"
                name="legal-entity"
                value="C-Corp"
                onClick={DisabledBtn}
              />
              <label htmlFor="myRadio4">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[0].tiles[3].tile_icon}
                    alt={stepsData.acf.step[0].tiles[3].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[0].tiles[3].tile_icon_active}
                    alt={stepsData.acf.step[0].tiles[3].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[0].tiles[3].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[0].tiles[3].tile_description}
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>      
  );
};
