import React from "react";

import MultiStep from "../services/react-multistep";
import StepOne from "./steps/stepOne";
import StepTwo from "./steps/stepTwo";
import StepThree from "./steps/stepThree";
import StepFour from "./steps/stepFour";
import StepFive from "./steps/stepFive";
import StepSix from "./steps/stepSix";
import StepSeven from "./steps/stepSeven";
import StepEight from "./steps/stepEight";
import StepNine from "./steps/stepNine";
import StepTen from "./steps/stepTen";
import "../css/normalize.min.css";
import { isEmbedCalculator } from "../helpers/queryParam"; 

const steps = [
  {
    name: "legal entity",
    component: <StepOne />,
  },
  {
    name: "Revenue",
    component: <StepTwo />,
  },
  {
    name: "Expenses",
    component: <StepThree />,
  },
  {
    name: "Benefits",
    component: <StepFour />,
  },
  {
    name: "StepFive",
    component: <StepFive />,
  },
  {
    name: "Health Coverage",
    component: <StepSix />,
  },
  {
    name: "StepSeven",
    component: <StepSeven />,
  },
  {
    name: "Back Office",
    component: <StepEight />,
  },
  {
    name: "Vendor Cost",
    component: <StepNine />,
  },
  {
    name: "Taxes",
    component: <StepTen />,
  },
];

const stepsForm = () => {
  const isEmbed = isEmbedCalculator();
  return (
    <div className={isEmbed ? "full-container": "container"}>
      <div className={isEmbed ? "full-main": "main"}>
        <div className="row">
          <MultiStep showNavigation={true} steps={steps} className="content" />
        </div>
      </div>
    </div>  
  )
};

export default stepsForm;
