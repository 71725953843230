import React from "react";

import analysisService from "../services/analysisService";
import { Recommendations } from "./assets/Recommendations";
import { VariantsReport } from "./assets/VariantsReport";
import { baseReportData } from "../data/baseReportData";
import wordpressService from "../services/wordpressService";
import $ from "jquery"; 
import { isEmbedCalculator } from "../helpers/queryParam";

class Print extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportData: baseReportData,      
    };
  }

  componentDidMount() {
    (async () => {
      this.setState({
        reportData: await wordpressService.getData("report"),
      });      
    })();     
  }    

  render() {
    let analysis = analysisService.analysis(this.state.reportData);
    const isEmbed = isEmbedCalculator();

    $(document).ready(function($) {
      window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
    });

    return (      
      <>       
        <div className="report__inner" id={'recommended'}>
          <div className="report__text">
            <h2 className="report__title">Recommended next steps:</h2>
            <div className="recommendation">
              {analysis.recommendation.map((recom, index) => (
                <Recommendations
                  key={index}
                  recommendation={recom.text}
                  title={recom.title}
                  icons={recom.icons}
                  count={recom.count}
                  benefits={recom.benefits}
                  note={recom.note}
                  link={recom.link}
                  url={recom.url}
                />
              ))}
            </div>
          </div>
          <div className="report__recom">
            <VariantsReport />
          </div>
        </div>
        {!isEmbed && <div className="report__footer">
          <h3 className="report__footer-title">Safe and Secure</h3>
          <p className="report__footer-descr">
            Formations offers self-employed individuals an S-Corp management solution and is not providing legal or accounting advice. This calculator is for illustrative purposes only and is not intended to be a substitute for consultation with your tax advisor. Calculations are based on input submitted by the user and data collected form the IRS and the Bureau of Labor Statistics. For personalized consultation based on more accurate information, schedule a call with one of Formations experts.
          </p>
          <div className="report__footer-bottom">
            <div className="report__footer-text">
              Formations. All rights reserved.
            </div>
            <div className="report__footer-cols">
              <div className="report__footer-item">
                <p>OFFICE </p>
                <p>12220 113th Ave NE, Suite #100</p>
                <p>Kirkland, WA 98034</p>
              </div>
              <div className="report__footer-item">
                <p>CONTACT</p>
                <a href="mailto:team@formationscorp.com">
                  team@formationscorp.com
                </a>
                <p>1.800.868.1186</p>
              </div>
            </div>
          </div>
        </div>}
      </>
    );    
  }
}
export default Print;
