import React, { useEffect, useState } from "react";
import { Field } from "formik";

import wordpressService from "../../services/wordpressService";
import { baseStepsData } from "../../data/baseStepsData";
import icon from "../../images/icon.png";
import Popup from "reactjs-popup";
import { disableNextButton } from "./common";

export default () => {
  const [stepsData, setStepsData] = useState(baseStepsData);

  const SidebarExpertTip = () => {
    if (stepsData.acf.step[3].sidebar.sidebar_expert_tip) {
      return <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[3].sidebar.sidebar_expert_tip}} />;
    } else {
      return null;
    }
  };

  const SidebarLink = () => {
    if (stepsData.acf.step[3].sidebar.sidebar_link.title) {
      return (
        <a
          href={stepsData.acf.step[3].sidebar.sidebar_link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar__link"
        >
          {stepsData.acf.step[3].sidebar.sidebar_link.title}
        </a>
      );
    } else {
      return null;
    }
  };

  const DisabledBtn = () => {
    const shouldDisable = !(document.getElementById("myCheckbox1").checked ||
      document.getElementById("myCheckbox2").checked ||
      document.getElementById("myCheckbox3").checked);
    disableNextButton(shouldDisable)
  };

  useEffect(() => {
    if (document.getElementById("myCheckbox1")) {
      DisabledBtn();
    }
  }, [])

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("steps"));
    })();
  }, []);  

  return (       
    <div className="text">
      <h2 className="text__title">
        What type of benefits do you have today?
      </h2>
      <Popup
        trigger={
          <div className="text__link text__link-mobile">
            <img src={icon} alt="sidebar" />
            What does this mean?
          </div>
        }            
        modal
        className="sidebar__popup"
      >
        {(close) => (
          <div className="sidebar__inner sidebar__inner-mobile">
            <div className="sidebar__close" onClick={close}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#404040" fillOpacity="0.87"/>
              </svg>
            </div>
            <div className="sidebar__block-text">
              <h3 className="sidebar__title">
                {stepsData.acf.step[3].sidebar.sidebar_title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[3].sidebar.sidebar_description}} />
            </div>
            <div className="sidebar__block-link">
              <div className="sidebar__subtitle">
                {stepsData.acf.step[3].sidebar.sidebar_title_2}
              </div>
              <SidebarExpertTip />
              <SidebarLink />
            </div>
          </div>
        )}
      </Popup>
      <div className="row">
        <div className="text__inner">
          <div className="row">
            <div className="text__checkbox">
              <Field
                type="checkbox"
                id="myCheckbox1"
                name="benefits"
                value="Retirement savings (IRA, 401K, etc.)"
                onClick={DisabledBtn}
              />
              <label htmlFor="myCheckbox1">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[3].tiles[0].tile_icon}
                    alt={stepsData.acf.step[3].tiles[0].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[3].tiles[0].tile_icon_active}
                    alt={stepsData.acf.step[3].tiles[0].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[3].tiles[0].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[3].tiles[0].tile_description}
                  </span>
                </div>
              </label>
            </div>
            <div className="text__checkbox">
              <Field
                type="checkbox"
                id="myCheckbox2"
                name="benefits"
                value="Flexible Savings Account (FSA)"
                onClick={DisabledBtn}
              />
              <label htmlFor="myCheckbox2">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[3].tiles[1].tile_icon}
                    alt={stepsData.acf.step[3].tiles[1].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[3].tiles[1].tile_icon_active}
                    alt={stepsData.acf.step[3].tiles[1].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[3].tiles[1].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[3].tiles[1].tile_description}
                  </span>
                </div>
              </label>
            </div>
            <div className="text__checkbox">
              <Field
                type="checkbox"
                id="myCheckbox3"
                name="benefits"
                value="Health Savings Account (HSA)"
                onClick={DisabledBtn}
              />
              <label htmlFor="myCheckbox3">
                <span className="text__icon">
                  <img
                    src={stepsData.acf.step[3].tiles[2].tile_icon}
                    alt={stepsData.acf.step[3].tiles[2].tile_title}
                  />
                  <img
                    src={stepsData.acf.step[3].tiles[2].tile_icon_active}
                    alt={stepsData.acf.step[3].tiles[2].tile_title}
                  />
                </span>
                <div className="text__content">
                  <span className="text__title-card">
                    {stepsData.acf.step[3].tiles[2].tile_title}
                  </span>
                  <span className="text__descr">
                    {stepsData.acf.step[3].tiles[2].tile_description}
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>      
  );
};
