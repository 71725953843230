import Cookies from "universal-cookie";
import { GoogleSpreadsheet } from "google-spreadsheet";

class GoogleService {
  gettingIp = async () => {
    const api_url = await fetch("https://api.ipify.org?format=json");
    return (await api_url.json()).ip;
  };

  getAnnualIncome(data) {
    let results = {
      40000: "Less than $40K",
      76600: "$40K-$80K",
      113200: "$80K-$150K",
      150000: "Over $150K",
      default: "",
    };
    return results[data] || results["default"];
  }

  getBusinessExpenses(data) {
    let results = {
      10000: "Less than $10K",
      19000: "$10K-$20K",
      28000: "$20K-$30K",
      37000: "$30K-$40K",
      46000: "$40K-$50K",
      55000: "$50K-$60K",
      64000: "$60K-$70K",
      73000: "$70K-$80K",
      82000: "$80K-$90K",
      91000: "$90K-$100K",
      100000: "Over $100K",
      default: "",
    };
    return results[data] || results["default"];
  }

  getAnnuallyBenefits(data) {
    let results = {
      5000: "Less than $5,000 per year",
      15000: "$5,000 to $15,000 per year",
      20000: "$15,000 to $30,000 per year",
      30000: "Over $30,000 per year",
      40000: "I am not sure",
      default: "",
    };
    return results[data] || results["default"];
  }

  getHealthCoverage(data) {
    let results = {
      2000: "Less than $2,000",
      4000: "$2,000-$3,500",
      6000: "$3,500-$5,000",
      8000: "$5,000-$8,000",
      10000: "$8,000-$12,000",
      12000: "Over $12,000",
      default: "",
    };
    return results[data] || results["default"];
  }

  getSpendTime(data) {
    let results = {
      10: "Less than 10 hours per month (about 2 hours per week)",
      20: "10 hours - 20 hours per month (about half a day per week)",
      30: "20 hours - 40 hours per month (about a full day every week)",
      40: "Over 40 hours per month (more than a day per week)",
      default: "",
    };
    return results[data] || results["default"];
  }

  getManagementCosts(data) {
    let results = {
      0: "$0 - I do it all by myself",
      1250: "Less than $500 per year",
      2500: "$500-$1,500 per year",
      3750: "$1,500-$3,500 per year",
      5000: "$3,500-$5,000 per year",
      6250: "$5,000-$7,500 per year",
      7500: "Over $7500 per year",
      default: "",
    };
    return results[data] || results["default"];
  }

  formatGogglePost(name, data, insurance = false) {
    if (name === "annual-income") {
      return this.getAnnualIncome(data);
    } else if (name === "business-expenses") {
      return this.getBusinessExpenses(data);
    } else if (name === "annually-benefits") {
      return this.getAnnuallyBenefits(data);
    } else if (name === "health-coverage") {
      return this.getHealthCoverage(data);
    } else if (name === "spend-time") {
      return this.getSpendTime(data);
    } else if (name === "management-costs") {
      return this.getManagementCosts(data);
    } else if (name === "taxes") {
      return data;
    }
  }

  googlePost(valueBenefits, valueHealthCoverage) {
    (async () => {
      const ip = await this.gettingIp();

      const SPREADSHEET_ID = "1N6VBrxHi5vEmo1_kkU6a-_mSr4G8k6eb08V93DkACwg";
      const SHEET_ID = "0";
      const CLIENT_EMAIL =
        "formations@calculator-answers.iam.gserviceaccount.com";
      const PRIVATE_KEY =
        "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDAId7jVjM2nqKc\n+8hvcscXYGhdMEs3iWs+/QqM8rDRbSfQ4xz8Str0CNWKQrXiThVsdPx59HUB8yCb\nKRn9gmt+4JeV+ahWCh1azKQkvNrsECGPcdmIO4RmdtZkVlnhZjw2ranhDOBFj5dJ\nin0CRUffld/8qKHzQWQZ+RqfYxn8F40prll/FjmMllnrl9IyGKs8qId3BYaLr3k9\nOa+6p2AFWP7/YEYUxW52YOLOeWXWe1eyJdWLVPBl0u4F41c7feMOX91XxPuZ2+Mc\nXOz+YCmrqEycIGC9Et0/I/2A/70OgiXR+c+u+wGsT8KF7a48yDbzTNBmKE6ifxiK\nvReYu1ejAgMBAAECggEAF9U7wkSDls9nyYRcESMSOj6dw42Fjw6PTKFz6RcDwv7A\n1SMPCea5MVNVNdDfogJgmvMKDJC74PGJRmX7UNyYPAVdGF//NykLCiPfaqU4nNbF\navdK7iC/5DvyjUC+5nxZlMjHDWEdAtP0hs1Ug28Xos8DGWLLsBKY+bXSTRosc346\n0sYBde+x+r+tvqrlFf45n6h+pIlMfSeMzy5eWked7C57RyIUsjJurJiYR1nVR7/u\n42henSUvs4kh8e1VS52MQJV3rDff1koRxmt5m9uJdc2lQ+YcRjjoQ9AHmPZpv6GH\nUXr8CIey0Shh5LrsQc6hDtouaZDVevISvunB7cO5RQKBgQD/rDTQ6PqPeRGnrJQ6\n2GDTVLZWxDLil+5GTvuOYR+yK0Mt7kXouucbR+tN8p4cX9dz9fQ6V0Kugs0qRbn3\nYn4bBezsX7tH4drnZdx26SBXA1mcNjlUWiXJvvk5FKrkiBiygZUu7LkCN9WaQoA5\nepIL6HkJQcmL6jZR52e6QEz/5wKBgQDAYNb5NovFtfCpKoJtC/2LGuC4Kyomq2+j\n7RZzlPP7w98ytudTB3xk8Os7pg1OGwtIkmnxD6S1Jl6heLujvgRV/KYi6BYroOSX\nv1e6S9E8H5LI2U1u+NBfiu1tVk289DWunCIiyBeJv7uLkHloM9kGMwRb6PS/S1uZ\n4APbanxi5QKBgCs2qxKoXjlDyFUj2UX87pipl8wgi007Sr29ec0940KVB1zLCivk\n80zsIfxhxpGbLWpfJvF5cD7Ywt6jPGRw1Emy/bGSGLB0nzA/Sj0a+Clc2peJnrUA\nK6Tmz27zbFMGIc6CEnoE7txOHZ9eIBgW/Hpgx5xl7y/j3VyIlUKovZr/AoGAYc1Z\nvRzz9jHHBdmssQ+VeLDTag3Xwg0KFsaj0WL6nxY3VKsT82ws24x3qr2CqiCpnd2B\nqqIPVGELXv/oNRiXT7fAJqLjEo8bZ+JJEVcWKoDKuP1wlmg8VzZj+mAT6WomGH0c\nBZ7ACLHHAO8UKduiC7rIDoniU5ZWHWZ7z4LVddkCgYAaoRWEwlxwIvogAmZyupK5\nkdjsnCUVBent5TnjKuOKA3s2WRthBRCgrmzAUtIe6LPA4AM38s14plfjBniDpR0w\nG8WnhK+mCNGUBmFQ1Z7uaVARQevNAtmZR43VpUzW7fCIH/zzKlka1/6+hkdCw20Q\n2bYgvhZ4H6VgoTc3ETi4xw==\n-----END PRIVATE KEY-----\n";

      const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

      const appendSpreadsheet = async (row) => {
        try {
          await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL,
            private_key: PRIVATE_KEY,
          });

          await doc.loadInfo();

          const sheet = doc.sheetsById[SHEET_ID];
          await sheet.addRow(row);
        } catch (e) {
          console.error("Error: ", e);
        }
      };

      const cookies = new Cookies();
      const cookiesRes = cookies.get("formationsCalculator");
      const date = new Date(Date.now()).toLocaleString();

      if (cookiesRes["benefits"].length !== 0) {
        valueBenefits = cookiesRes["benefits"].join(";");
      } else {
        valueBenefits = "I have no benefits";
      }

      if (cookiesRes["medical-benefits"].length !== 0) {
        valueHealthCoverage = cookiesRes["medical-benefits"].join(";");
      } else if (cookiesRes["value-insurance"] === true) {
        valueHealthCoverage =
          "I'm on someone else's insurance (partner, parents)";
      } else {
        valueHealthCoverage = "I don't have health insurance";
      }

      const newRow = {
        IP: ip,
        Date: date,
        Legal_Entity: cookiesRes["legal-entity"].toString(),
        Income: this.formatGogglePost(
          "annual-income",
          cookiesRes["annual-income"].toString()
        ),
        Business_Expense: this.formatGogglePost(
          "business-expenses",
          cookiesRes["business-expenses"].toString()
        ),
        Benefits: valueBenefits,
        Benefits_additionally: this.formatGogglePost(
          "annually-benefits",
          cookiesRes["annually-benefits"].toString()
        ),
        Health_Coverage: valueHealthCoverage,
        Health_Coverage_additionally: this.formatGogglePost(
          "health-coverage",
          cookiesRes["health-coverage"].toString()
        ),
        Business_Finance: this.formatGogglePost(
          "spend-time",
          cookiesRes["spend-time"].toString()
        ),
        Cost_Management: this.formatGogglePost(
          "management-costs",
          cookiesRes["management-costs"].toString()
        ),
        Tax_Payment: this.formatGogglePost(
          "taxes",
          cookiesRes["taxes"].toString()
        ),
      };

      appendSpreadsheet(newRow);
    })();
  }
}

export default new GoogleService();
