import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import Result from "./components/result";
import StepsForm from "./components/stepsForm";
import Home from "./components/home";
import Report from "./components/report";
import Form from "./components/form";
import Header from "./components/assets/Header";
import Footer from "./components/assets/Footer";
import { HubspotForm } from "./components/assets/HubspotForm";
import wordpressService from "./services/wordpressService";
import { initializeTagManager } from "./services/tagManager";
import { isEmbedCalculator } from "./helpers/queryParam";

import "./css/app.css";

initializeTagManager();

(async () => {
  await wordpressService.buildCache();
})();

const isEmbed = isEmbedCalculator();

ReactDOM.render(
  <Router>
    {!isEmbed && <Header />}
    <div height="100%" width="100%">
      <Switch>
        <Route path="/form">
          <Form />
        </Route>
        <Route path="/report">
          <Report />
        </Route>
        <Route path="/steps">
          <StepsForm />
        </Route>
        <Route path="/result">
          <Result />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
    {!isEmbed && <Footer />}
    <HubspotForm />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
