import TagManager from "react-gtm-module";

const defaultArgs = {
  dataLayerName: "PageDataLayer",
};

const tagManagerInitializeArgs = {
  ...defaultArgs,
  gtmId: "GTM-PBV2KKS",
  containerId: "UA-189771855-1",
};
export const initializeTagManager = () =>
  TagManager.initialize(tagManagerInitializeArgs);

const receiveStepArgs = (index) => {
  const step = index + 1;

  return {
    [`Step ${step}`]: `/steps/step${step}`,
  };
};
const receiveFormModificationArgs = (formModification) => {
  if (formModification === "Form") {
    return { [formModification]: "/steps/form_open" };
  } else if (formModification === "Form Complete") {
    return { [formModification]: "/steps/form_complete" };
  }
};
const receiveDataLayerArgs = (argsArray) => {
  let dataLayerArgs = defaultArgs;

  if (argsArray[0] === "step") {
    dataLayerArgs = {
      ...dataLayerArgs,
      dataLayer: receiveStepArgs(argsArray[1]),
    };
  } else if (argsArray[0] === "formModification") {
    dataLayerArgs = {
      ...dataLayerArgs,
      dataLayer: receiveFormModificationArgs(argsArray[1]),
    };
  }

  return dataLayerArgs;
};
export const dataLayerTagManager = (argsArray) =>
  TagManager.dataLayer(receiveDataLayerArgs(argsArray));
