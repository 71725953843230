export const baseStepsData = {
  "acf": {
      "step": [
          {
              "tiles": [
                  {
                      "tile_icon": "assets/uploads/2020/11/1.svg",
                      "tile_icon_active": "assets/uploads/2020/11/1-1.svg",
                      "tile_title": "Sole Prop",
                      "tile_description": "Unincorporated business owned and run by one individual."
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/2.svg",
                      "tile_icon_active": "assets/uploads/2020/11/2-1.svg",
                      "tile_title": "LLC",
                      "tile_description": "Business structure whereby the owners are not personally liable for the company's debts or liabilities."
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/4.svg",
                      "tile_icon_active": "assets/uploads/2020/11/4-1.svg",
                      "tile_title": "S-Corp",
                      "tile_description": "A corporation that elects to pass corporate income, losses, deductions, and credits through to its shareholders for federal tax purposes."
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/3.svg",
                      "tile_icon_active": "assets/uploads/2020/11/3-1.svg",
                      "tile_title": "C-Corp",
                      "tile_description": "A legal structure for a corporation in which the owners, or shareholders, are taxed separately from the entity."
                  }
              ],
              "sidebar": {
                  "sidebar_title": "Your Business",
                  "sidebar_description": "<p>Most self-employed individuals are sole props, but that’s like smoking In the 80’s &#8211; It’s bad for you, but you just don’t know it yet.</p>\n",
                  "sidebar_title_2": "Expert Tip:",
                  "sidebar_expert_tip": "<p>At the very least, you should be incorporated as an LLC so you can have legal protection.</p>\n",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Revenue",
                  "sidebar_description": "<p>Knowing your revenue (accounting-speak for money your business generates before you subtract business expenses) will help us give you more information about how best to structure your business and what actions you should take to improve your business health.</p>\n",
                  "sidebar_title_2": "Expert Tip",
                  "sidebar_expert_tip": "<p>If you don’t know yet, you can use last year’s revenue to estimate what it will be this year.</p>\n",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Business Expenses ",
                  "sidebar_description": "<p>All of the basic expenses necessary to run a business are generally considered business expenses and are tax-deductible. For example, office rent, equipment and supplies, phone and utility costs, legal and accounting services, marketing, and subscriptions to business publications.</p>\n",
                  "sidebar_title_2": "Expert Tip",
                  "sidebar_expert_tip": "<p>Don&#8217;t over think it. Either use last year’s expenses or think of a typical month’s expenses and multiply by 12.</p>\n",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": [
                  {
                      "tile_icon": "assets/uploads/2020/11/5.svg",
                      "tile_icon_active": "assets/uploads/2020/11/5-1.svg",
                      "tile_title": "Retirement Savings ",
                      "tile_description": "(IRA, SEP IRA, 401K, etc.)"
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/6.svg",
                      "tile_icon_active": "assets/uploads/2020/11/6-1.svg",
                      "tile_title": "Flexible Savings Account",
                      "tile_description": ""
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/7.svg",
                      "tile_icon_active": "assets/uploads/2020/11/7-1.svg",
                      "tile_title": "Health Savings Account",
                      "tile_description": ""
                  }
              ],
              "sidebar": {
                  "sidebar_title": "Benefits",
                  "sidebar_description": "<p>Optimizing your benefits as a tax-savings mechanism increases your total tax savings by up to $15K per year and helps you ensure your future financial wellbeing.</p>\n",
                  "sidebar_title_2": "",
                  "sidebar_expert_tip": "",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Annual Savings",
                  "sidebar_description": "<p>Based on your household status, incorporation, and savings plan, you can put up as much as $40K every year in savings accounts, reducing your tax liability and payments.</p>\n",
                  "sidebar_title_2": "Expert Tip",
                  "sidebar_expert_tip": "<p>As a self-employed individual, you have several options for retirement, health and flexbilie savings, but those can be optimized by moving to an S-Corp, where retirement savings can be maximized with the right plan.</p>\n",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": [
                  {
                      "tile_icon": "assets/uploads/2020/11/8.svg",
                      "tile_icon_active": "assets/uploads/2020/11/8-1.svg",
                      "tile_title": "Health",
                      "tile_description": ""
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/9.svg",
                      "tile_icon_active": "assets/uploads/2020/11/9-1.svg",
                      "tile_title": "Dental",
                      "tile_description": ""
                  },
                  {
                      "tile_icon": "assets/uploads/2020/11/10.svg",
                      "tile_icon_active": "assets/uploads/2020/11/10-1.svg",
                      "tile_title": "Vision",
                      "tile_description": ""
                  }
              ],
              "sidebar": {
                  "sidebar_title": "Health Coverage",
                  "sidebar_description": "<p>You can select more than one option.</p>\n",
                  "sidebar_title_2": "",
                  "sidebar_expert_tip": "<p>If you are a dependent on someone else’s coverage, simply select that option.</p>\n",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Health Coverage Cost",
                  "sidebar_description": "<p>Please include only the cost of the coverage, not your annual health expenses.</p>\n",
                  "sidebar_title_2": "",
                  "sidebar_expert_tip": "",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Back Office Work",
                  "sidebar_description": "<p>The best way to calculate the time you spend on “back-office” is to add up all the time spent on boring tasks that you can’t wait to be done with. This includes things like organizing receipts, updating accounting software, and creating invoices.</p>\n<p>This is also called your “opportunity cost,” meaning time that you could be spending on work that contributes to your business, personal well-being, or family.</p>\n",
                  "sidebar_title_2": "Tip for Calculating ",
                  "sidebar_expert_tip": "<p>About two hours per week = Less than 10 hours per month<br />\nAbout half a day per week = 10 hours- 20 hours per month<br />\nAbout a full day every week = 20 hours &#8211; 40 hours per month<br />\nMore than a day per week = Over 40 hours per month</p>\n",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Back-Office Management Cost",
                  "sidebar_description": "<p>Please include all the solutions and services you are paying for. Are you paying for a bookkeeper or a bookkeeping system? Do you have an accountant for your business? Do you pay someone to file your taxes? Do you have an admin who manages your invoices, receipts, bill-pays, and transactions?</p>\n<p>Don’t include the cost to manage your personal finances, like your investment portfolio app or your insurance agent.</p>\n",
                  "sidebar_title_2": "",
                  "sidebar_expert_tip": "",
                  "sidebar_link": ""
              }
          },
          {
              "tiles": false,
              "sidebar": {
                  "sidebar_title": "Business Taxes",
                  "sidebar_description": "<p>Please include the annual taxes you paid on your business last year. It&#8217;s okay to estimate.</p>\n",
                  "sidebar_title_2": "Expert Tip",
                  "sidebar_expert_tip": "<p>Check your tax return. If you are a sole-prop (1099) and filed your business taxes as part of your personal taxes, you&#8217;ll find your Self-Employment Taxes on line 23 of your form 1040 of your last year&#8217;s tax return.</p>\n",
                  "sidebar_link": ""
              }
          }
      ],
      "footer_bool": false
  }
};
