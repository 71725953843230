import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Cookies from "universal-cookie";

import wordpressService from "../services/wordpressService";
import { dataLayerTagManager } from "../services/tagManager";
import { SelectField } from "./elements/SelectField";
import hubspotService from "../services/hubspotService";
import "reactjs-popup/dist/index.css";
import { baseReportData } from "../data/baseReportData";
import { Link, useHistory } from "react-router-dom";
import { isEmbedCalculator } from "../helpers/queryParam";
import googleService from "../services/googleService";

const InputField = ({ field, form, ...props }) => {
  return <input {...field} {...props} />;
};

export default ({ closeFunction }) => {
  const [reportData, setStepsData] = useState(baseReportData);

  const [formData, setFormData] = useState({
    acf: {
      calculator_form_image: {
        title: "",
        url: "",
        link: "",
      },
    },
  });

  useEffect(() => {
    (async () => {
      setFormData(await wordpressService.getData("form"));
      setStepsData(await wordpressService.getData("report"));
    })();
  }, []);

  const cookies = new Cookies();
  let formikFormData = cookies.get("formationsCalculator");

  let history = useHistory();
  const isEmbed = isEmbedCalculator();
  return (
    <div className={isEmbed ? "full-container": "container"}>
      <div className={`${isEmbed ? "full-main": "main"} popup`}>
        <div className="row">
          <div className="content">
            <div className="text">
              <h2
                className="text__title_form"
                onClick={() =>
                  dataLayerTagManager(["formModification", "Form"])
                }
              >
                GET OUR RECOMMENDATIONS
              </h2>
              <h3 className="text__subtitle">
                Please provide additional information that will help us
                personalize the recommendations for you
              </h3>

              <Formik
                enableReinitialize
                initialValues={formikFormData}
                onSubmit={(values, actions) => {
                  cookies.set("formationsCalculator", values, {
                    path: "/",
                    sameSite: "strict",
                  });
                  actions.setSubmitting(false);
                  hubspotService.hubspotPost(reportData);
                  // sending to google instead of renderFormButton in src/services/react-multistep.js
                    // because we cannot trigger the popup and googlePost at the same time.
                  googleService.googlePost();
                  history.push(`/report${isEmbed ? '?embed' : ''}#recommended`);
                }}
              >
                <Form>
                  <div className="row">
                    <div className="form__fields">
                      <div className="form-field">
                        <label htmlFor="first-name">
                          First Name <span>*</span>
                        </label>
                        <Field
                          id="first-name"
                          name="first-name"
                          placeholder="First name"
                          component={InputField}
                          required
                        />
                      </div>
                      <div className="form-field">
                        <label htmlFor="last-name">
                          Last Name <span>*</span>
                        </label>
                        <Field
                          id="last-name"
                          name="last-name"
                          placeholder="Last name"
                          component={InputField}
                          required
                        />
                      </div>
                      <div className="form-field">
                        <label htmlFor="business-name">Number of employees</label>
                        <Field
                          name={"select-employees"}
                          component={SelectField}
                          options={[
                            {
                              value: "No, it's just me.",
                              label: "No, it's just me.",
                            },{
                              value: "Yes, I have 1-5 employees.",
                              label: "Yes, I have 1-5 employees.",
                            },{
                              value: "Yes, I have more than 5 employees.",
                              label: "Yes, I have more than 5 employees.",
                            },
                          ]}
                          defaultValue={{
                            value: "number of employees",
                            label: "Number of employees",
                          }}
                          className={"select-field"}
                        />
                      </div>
                      <div className="form-field">
                        <label htmlFor="email">
                          Email address <span>*</span>
                        </label>
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Email address"
                          component={InputField}
                          required
                        />
                      </div>
                      <div className="form-field">
                        <Field
                          name={"select-industry"}
                          component={SelectField}
                          options={[
                            {
                              value: "Agriculture, Forestry, and Fishing",
                              label: "Agriculture, Forestry, and Fishing",
                            },
                            { value: "Construction", label: "Construction" },
                            { value: "Retail Trade", label: "Retail Trade" },
                            {
                              value: "Manufacturing",
                              label: "Manufacturing",
                            },
                            { value: "IT Services", label: "IT Services" },
                            {
                              value: "Finance and Insurance",
                              label: "Finance and Insurance",
                            },
                            { value: "Real Estate", label: "Real Estate" },
                            {
                              value:
                                "Professional, Scientific, and Technical Services",
                              label:
                                "Professional, Scientific, and Technical Services",
                            },
                            {
                              value: "Educational Services",
                              label: "Educational Services",
                            },
                            {
                              value: "Health Care and Social Assistance",
                              label: "Health Care and Social Assistance",
                            },
                            {
                              value: "Arts, Entertainment, and Recreation",
                              label: "Arts, Entertainment, and Recreation",
                            },
                            {
                              value: "Accommodation and Food Services",
                              label: "Accommodation and Food Services",
                            },
                            { value: "Other", label: "Other" },
                          ]}
                          defaultValue={{
                            value: "industry",
                            label: "Industry",
                          }}
                          className={"select-field"}
                        />
                      </div>
                      <div className="form-field">
                        <Field
                          name={"select-state"}
                          component={SelectField}
                          options={[
                            { value: "Alabama", label: "Alabama" },
                            { value: "Alaska", label: "Alaska" },
                            { value: "Arizona", label: "Arizona" },
                            { value: "Arkansas", label: "Arkansas" },
                            { value: "California", label: "California" },
                            { value: "Colorado", label: "Colorado" },
                            { value: "Connecticut", label: "Connecticut" },
                            { value: "Delaware", label: "Delaware" },
                            { value: "Florida", label: "Florida" },
                            { value: "Georgia", label: "Georgia" },
                            { value: "Hawaii", label: "Hawaii" },
                            { value: "Idaho", label: "Idaho" },
                            { value: "Illinois", label: "Illinois" },
                            { value: "Indiana", label: "Indiana" },
                            { value: "Iowa", label: "Iowa" },
                            { value: "Kansas", label: "Kansas" },
                            { value: "Kentucky", label: "Kentucky" },
                            { value: "Louisiana", label: "Louisiana" },
                            { value: "Maine", label: "Maine" },
                            { value: "Maryland", label: "Maryland" },
                            {
                              value: "Massachusetts",
                              label: "Massachusetts",
                            },
                            { value: "Michigan", label: "Michigan" },
                            { value: "Minnesota", label: "Minnesota" },
                            { value: "Mississippi", label: "Mississippi" },
                            { value: "Missouri", label: "Missouri" },
                            { value: "Montana", label: "Montana" },
                            { value: "Nebraska", label: "Nebraska" },
                            { value: "Nevada", label: "Nevada" },
                            {
                              value: "New Hampshire",
                              label: "New Hampshire",
                            },
                            { value: "New Jersey", label: "New Jersey" },
                            { value: "New Mexico", label: "New Mexico" },
                            { value: "New York", label: "New York" },
                            {
                              value: "North Carolina",
                              label: "North Carolina",
                            },
                            { value: "North Dakota", label: "North Dakota" },
                            { value: "Ohio", label: "Ohio" },
                            { value: "Oklahoma", label: "Oklahoma" },
                            { value: "Oregon", label: "Oregon" },
                            { value: "Pennsylvania", label: "Pennsylvania" },
                            { value: "Rhode Island", label: "Rhode Island" },
                            {
                              value: "South Carolina",
                              label: "South Carolina",
                            },
                            { value: "South Dakota", label: "South Dakota" },
                            { value: "Tennessee", label: "Tennessee" },
                            { value: "Texas", label: "Texas" },
                            { value: "Utah", label: "Utah" },
                            { value: "Vermont", label: "Vermont" },
                            { value: "Virginia", label: "Virginia" },
                            { value: "Washington", label: "Washington" },
                            {
                              value: "West Virginia",
                              label: "West Virginia",
                            },
                            { value: "Wisconsin", label: "Wisconsin" },
                            { value: "Wyoming", label: "Wyoming" },
                          ]}
                          defaultValue={{ value: "state", label: "State" }}
                          className={"select-field"}
                        />
                      </div>
                    </div>
                    <div className="form__buttons">
                      <button
                        type="submit"
                        className="button button-yellow"
                        onSubmit={() =>
                          dataLayerTagManager([
                            "formModification",
                            "Form Complete",
                          ])
                        }
                      >
                        See Results
                      </button>
                      <button
                        className="button button-white button-desktop"
                        onClick={closeFunction}
                      >
                        Cancel
                      </button>
                      <Link
                        to="/result"
                        className="button button-white button-mobile"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
          <aside className="sidebar sidebar_form">
            <a
              href={formData.acf.calculator_form_image.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={formData.acf.calculator_form_image.url}
                alt={formData.acf.calculator_form_image.title}
              />
            </a>
          </aside>
        </div>
      </div>
    </div>
  );
};
