import React, { useEffect, useState } from "react";
import { Field } from "formik";

import { SelectField } from "../elements/SelectField";
import wordpressService from "../../services/wordpressService";
import { baseStepsData } from "../../data/baseStepsData";
import icon from "../../images/icon.png";
import Popup from "reactjs-popup";
import Cookies from "universal-cookie";
import { disableNextButton } from "./common";

export default () => {
  const [stepsData, setStepsData] = useState(baseStepsData);

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("steps"));
    })();
  }, []);

  const options = [
    { value: 5000, label: "Less than $5,000 per year" },
    { value: 15000, label: "$5,000 to $15,000 per year" },
    { value: 20000, label: "$15,000 to $30,000 per year" },
    { value: 30000, label: "Over $30,000 per year" },
    { value: 40000, label: "I am not sure" },
  ];

  const SidebarExpertTip = () => {
    if (stepsData.acf.step[4].sidebar.sidebar_expert_tip) {
      return <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[4].sidebar.sidebar_expert_tip}} />;
    } else {
      return null;
    }
  };

  const SidebarLink = () => {
    if (stepsData.acf.step[4].sidebar.sidebar_link.title) {
      return (
        <a
          href={stepsData.acf.step[4].sidebar.sidebar_link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar__link"
        >
          {stepsData.acf.step[4].sidebar.sidebar_link.title}
        </a>
      );
    } else {
      return null;
    }
  };  

  const cookies = new Cookies();
  const cookiesRes = cookies.get("formationsCalculator");
  let annuallyBenefits = cookiesRes["annually-benefits"];

  disableNextButton(annuallyBenefits === "")

  return (    
    <div className="text">
      <h2 className="text__title">
        How much are you putting aside annually for your benefits?
      </h2>
      <Popup
        trigger={
          <div className="text__link text__link-mobile">
            <img src={icon} alt="sidebar" />
            What does this mean?
          </div>
        }            
        modal
        className="sidebar__popup"
      >
        {(close) => (
          <div className="sidebar__inner sidebar__inner-mobile">
            <div className="sidebar__close" onClick={close}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#404040" fillOpacity="0.87"/>
              </svg>
            </div>
            <div className="sidebar__block-text">
              <h3 className="sidebar__title">
                {stepsData.acf.step[4].sidebar.sidebar_title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[4].sidebar.sidebar_description}} />
            </div>
            <div className="sidebar__block-link">
              <div className="sidebar__subtitle">
                {stepsData.acf.step[4].sidebar.sidebar_title_2}
              </div>
              <SidebarExpertTip />
              <SidebarLink />
            </div>
          </div>
        )}
      </Popup>          
      <div className="form-field__select">
        <Field
          name={"annually-benefits"}
          component={SelectField}
          options={options}              
        />
      </div>          
    </div>      
  );
};
