import React, { useEffect, useState } from "react";

import wordpressService from "../../services/wordpressService";
import { baseReportData } from "../../data/baseReportData";

export const VariantsReport = () => {
  const [reportData, setResultData] = useState(baseReportData);

  useEffect(() => {
    (async () => {
      setResultData(await wordpressService.getData("report"));
    })();
  }, []);

  return (
    <React.Fragment>
      {reportData.acf.sidebar_posts.sidebar_items.map((data, key) => {
        return (
          <div className="report__recom-item" key={key}>
            <div className="recom-item__image" style={{ overflow: "hidden" }}>
              <img src={data.image} alt={data.title} />
            </div>
            <a href={`${data.title_link}`} className="recom-item__title" target="_blank" rel="noopener noreferrer">{data.title}</a>            
            <p className="recom-item__descr">{data.description}</p>            
          </div>
        );
      })}
    </React.Fragment>
  );
};
