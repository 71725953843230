import React, { useEffect, useState } from "react";
import { Field } from "formik";

import { SelectField } from "../elements/SelectField";
import wordpressService from "../../services/wordpressService";
import { baseStepsData } from "../../data/baseStepsData";
import icon from "../../images/icon.png";
import Popup from "reactjs-popup";
import Cookies from "universal-cookie";
import { disableNextButton } from "./common";

export default () => {
  const [stepsData, setStepsData] = useState(baseStepsData);

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("steps"));
    })();
  }, []);

  const options = [
    { value: 10000, label: "Less than $10,000" },
    { value: 19000, label: "$10,000 to $20,000" },
    { value: 28000, label: "$20,000 to $30,000" },
    { value: 37000, label: "$30,000 to $40,000" },
    { value: 46000, label: "$40,000 to $50,000" },
    { value: 55000, label: "$50,000 to $60,000" },
    { value: 64000, label: "$60,000 to $70,000" },
    { value: 73000, label: "$70,000 to $80,000" },
    { value: 82000, label: "$80,000 to $90,000" },
    { value: 91000, label: "$90,000 to $100,000" },
    { value: 100000, label: "Over $100,000" },
  ];

  const SidebarExpertTip = () => {
    if (stepsData.acf.step[2].sidebar.sidebar_expert_tip) {
      return <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[2].sidebar.sidebar_expert_tip}} />;
    } else {
      return null;
    }
  };

  const SidebarLink = () => {
    if (stepsData.acf.step[2].sidebar.sidebar_link.title) {
      return (
        <a
          href={stepsData.acf.step[2].sidebar.sidebar_link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar__link"
        >
          {stepsData.acf.step[2].sidebar.sidebar_link.title}
        </a>
      );
    } else {
      return null;
    }
  };

  const cookies = new Cookies();
  const cookiesRes = cookies.get("formationsCalculator");
  let businessExpenses = cookiesRes["business-expenses"];

  disableNextButton(businessExpenses === "")

  return (
    <div className="text">
      <h2 className="text__title">
      What are your projected annual business expenses?
      </h2>
      <Popup
        trigger={
          <div className="text__link text__link-mobile">
            <img src={icon} alt="sidebar" />
            What does this mean?
          </div>
        }
        modal
        className="sidebar__popup"
      >
        {(close) => (
          <div className="sidebar__inner sidebar__inner-mobile">
            <div className="sidebar__close" onClick={close}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#404040" fillOpacity="0.87"/>
              </svg>
            </div>
            <div className="sidebar__block-text">
              <h3 className="sidebar__title">
                {stepsData.acf.step[2].sidebar.sidebar_title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[2].sidebar.sidebar_description}} />
            </div>
            <div className="sidebar__block-link">
              <div className="sidebar__subtitle">
                {stepsData.acf.step[2].sidebar.sidebar_title_2}
              </div>
              <SidebarExpertTip />
              <SidebarLink />
            </div>
          </div>
        )}
      </Popup>
      <div className="form-field__select">
        <Field
          name={"business-expenses"}
          component={SelectField}
          options={options}
        />
      </div>
    </div>
  );
};
