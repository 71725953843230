import React, { useEffect, useState } from "react";

import wordpressService from "../../services/wordpressService";
import { baseStepsData } from "../../data/baseStepsData";

export const Sidebar = ({step}) => {
  const [stepsData, setStepsData] = useState(baseStepsData);

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("steps"));      
    })();
  }, []);   

  return (
    <React.Fragment>      
      {stepsData.acf.step.map((data, key) => {
        if (step === key) {        
          return (
            <aside className="sidebar" key={key}>
              <div className="sidebar__inner">
                <div className="sidebar__block-text">
                  <h3 className="sidebar__title">
                    {data.sidebar.sidebar_title}
                  </h3>
                  <div className="sidebar__description" dangerouslySetInnerHTML={{__html: data.sidebar.sidebar_description}} />
                </div>
                <div className="sidebar__block-link">
                  <div className="sidebar__subtitle">
                    {data.sidebar.sidebar_title_2}
                  </div>
                  {data.sidebar.sidebar_expert_tip && (
                    <div className="sidebar__description" dangerouslySetInnerHTML={{__html: data.sidebar.sidebar_expert_tip}} />
                  )}   
                  {data.sidebar.sidebar_link.title && (
                    <a 
                      href={data.sidebar.sidebar_link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="sidebar__link"
                    >
                      {data.sidebar.sidebar_link.title}
                    </a>
                  )}             
                </div>
              </div>
            </aside>
          );
        } else return null;
      })}
    </React.Fragment>
  );
};