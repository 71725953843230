export const baseMenuData = {
  "term_id": 2,
  "name": "Main Menu",
  "slug": "main-menu",
  "term_group": 0,
  "term_taxonomy_id": 2,
  "taxonomy": "nav_menu",
  "description": "",
  "parent": 0,
  "count": 6,
  "filter": "raw",
  "items": []
};
