import React, { useEffect, useState } from "react";
import wordpressService from "../../services/wordpressService";
import { baseOptionsData } from "../../data/baseOptionsData";
import { baseMenuData } from "../../data/baseMenuData";

export default () => { 

  const [optionsData, setOptionsData] = useState(baseOptionsData);
  const [menuData, setMenuData] = useState(baseMenuData);

  useEffect(() => {
    (async () => {
      setOptionsData(await wordpressService.getData("options")); 
      setMenuData(await wordpressService.getData("menu"));      
    })();
  }, []);

  const year = new Date().getFullYear();   

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__top">
          <div className="footer__left">          
            <div className="footer__social-holder">
              <ul>
                {(optionsData.acf.linkedin) && (                  
                  <li className="ico-social ico-linkedin">
                    <a className="ico" href={optionsData.acf.linkedin} target="_blank" rel="noopener noreferrer">
                      <svg className="svg-inline--fa fa-linkedin-in fa-w-14" aria-hidden="true" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"></path></svg>
                    </a>                    
                  </li>                    
                )}  
                {(optionsData.acf.facebook) && (              
                  <li className="ico-social ico-facebook">
                    <a className="ico" href={optionsData.acf.facebook} target="_blank" rel="noopener noreferrer">
                      <svg className="svg-inline--fa fa-facebook-f fa-w-9" aria-hidden="true" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512" data-fa-i2svg=""><path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"></path></svg>
                    </a>
                  </li>
                )}
                {(optionsData.acf.twitter) && ( 
                  <li className="ico-social ico-twitter">
                    <a className="ico" href={optionsData.acf.twitter} target="_blank" rel="noopener noreferrer">
                      <svg className="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                    </a>
                  </li>
                )}
                {(optionsData.acf.email) && (
                  <li className="ico-social ico-email">
                    <a className="ico" href={optionsData.acf.email} target="_blank" rel="noopener noreferrer">
                      <svg className="svg-inline--fa fa-at fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="at" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z"></path></svg>
                    </a>
                  </li>
                )}
                {(optionsData.acf.youtube) && (
                  <li className="ico-social ico-youtube">
                    <a className="ico" href={optionsData.acf.youtube} target="_blank" rel="noopener noreferrer">
                      <svg className="svg-inline--fa fa-youtube fa-w-18" aria-hidden="true" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="footer__right">          
            <nav className="footer__nav">
              <ul>
                {menuData.items.map((data, key) => {
                  return (
                    <li key={key}>                
                      <a href={data.url}><span>{data.title}</span></a>
                    </li>                  
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__disclaimer" dangerouslySetInnerHTML={{__html: optionsData.acf.disclaimer_text}} />          
          <div className="footer__copyright">
            <div className="footer__copyright-text" dangerouslySetInnerHTML={{__html: `&copy;${year}&nbsp;${optionsData.acf.footer_copyright_text}`}} />        
            <a href="https://formationscorp.com/privacy-policy/" className="footer__privacy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
};