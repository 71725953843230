export const baseHomeData = {
  acf: {
    image: {
      ID: 2179,
      id: 2179,
      title: "image",
      filename: "formations-tax-calculator.png",
      filesize: 3699,
      url: "assets/uploads/2020/11/formations-tax-calculator.png",
      link: "https://formationscorp.com/business-growth-analyser/image/",
      alt: "",
      author: "7",
      description: "",
      caption: "",
      name: "image",
      status: "inherit",
      uploaded_to: 2075,
      date: "2020-11-13 11:34:16",
      modified: "2020-11-13 11:34:16",
      menu_order: 0,
      mime_type: "image/png",
      type: "image",
      subtype: "png",
      icon: "image/media/default.png",
      width: 378,
      height: 251,
      sizes: {
        thumbnail: "assets/uploads/2020/11/image-1-150x150.png",
        "thumbnail-width": 150,
        "thumbnail-height": 150,
        medium: "assets/uploads/2020/11/image-1-300x199.png",
        "medium-width": 300,
        "medium-height": 199,
        medium_large: "assets/uploads/2020/11/formations-tax-calculator.png",
        "medium_large-width": 378,
        "medium_large-height": 251,
        large: "assets/uploads/2020/11/formations-tax-calculator.png",
        "large-width": 378,
        "large-height": 251,
        "1536x1536": "assets/uploads/2020/11/formations-tax-calculator.png",
        "1536x1536-width": 378,
        "1536x1536-height": 251,
        "2048x2048": "assets/uploads/2020/11/formations-tax-calculator.png",
        "2048x2048-width": 378,
        "2048x2048-height": 251,
        "footer-cta": "assets/uploads/2020/11/formations-tax-calculator.png",
        "footer-cta-width": 192,
        "footer-cta-height": 127,
        "post-tile": "assets/uploads/2020/11/image-1-350x251.png",
        "post-tile-width": 350,
        "post-tile-height": 251,
        "half-width": "assets/uploads/2020/11/formations-tax-calculator.png",
        "half-width-width": 378,
        "half-width-height": 251,
        "team-thumb": "assets/uploads/2020/11/image-1-170x170.png",
        "team-thumb-width": 170,
        "team-thumb-height": 170,
        "how-icon": "assets/uploads/2020/11/image-1-150x100.png",
        "how-icon-width": 150,
        "how-icon-height": 100,
        "vp-hero-image": "assets/uploads/2020/11/formations-tax-calculator.png",
        "vp-hero-image-width": 378,
        "vp-hero-image-height": 251,
        "vp-cta-image": "assets/uploads/2020/11/formations-tax-calculator.png",
        "vp-cta-image-width": 378,
        "vp-cta-image-height": 251,
        icons: "assets/uploads/2020/11/image-1-50x33.png",
        "icons-width": 50,
        "icons-height": 33,
        "square-big": "assets/uploads/2020/11/formations-tax-calculator.png",
        "square-big-width": 378,
        "square-big-height": 251,
        "submenu-img": "assets/uploads/2020/11/formations-tax-calculator.png",
        "submenu-img-width": 175,
        "submenu-img-height": 116,
      },
    },
    button_text: "Start",
    title: "PREDICT YOUR SAVINGS",
    description:
      "<p>Take 5 minutes to answer a few questions and get recommendations on how to save more on your taxes.</p>\n<p>&nbsp;</p>\n<p>Get started by clicking below.</p>\n",
  },
};
