import React from "react";

export class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.integrity =
      "sha384-ngiekyr0tvQ9OuCqKq3NPR9w1qfLzDbQCOEPFzCVjXjydOu2NaRi8NyrIjV+Lku8";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "6637631",
          formId: "aff012b5-92cb-4562-9c1b-b90ee622018e",
          target: "#hubspotForm",
        });
      }
    });
  }

  render() {
    return (
      <div className="hubspot">
        <div className="hubspot__container">
          <div className="hubspot-form">
            <h2 className="section-title">Please fill in the form</h2>
            <div id="hubspotForm"></div>
          </div>
        </div>
      </div>
    );
  }
}
