import React, { useEffect, useState } from "react";
import $ from "jquery";
import wordpressService from "../../services/wordpressService";
import { baseOptionsData } from "../../data/baseOptionsData";
import { baseMenuData } from "../../data/baseMenuData";

export default () => { 
  
  const [optionsData, setOptionsData] = useState(baseOptionsData);
  const [menuData, setMenuData] = useState(baseMenuData);  

  useEffect(() => {
    (async () => {
      setOptionsData(await wordpressService.getData("options"));      
      setMenuData(await wordpressService.getData("menu"));       
    })();
  }, []);  

  $(function () {
    let element = $(".affix");

    $(document).ready(function () {
        affixElement(element);
    });

    if (element.length > 0) {

        window.addEventListener("scroll", function () {
            affixElement(element);
        })
      }
  });

  function affixElement(element) {

    element.each( function () {
      let height = $(this).hasClass("header-main") ? $(window).scrollTop() : ($(window).scrollTop() + 94);
      let position = $(this).offset();

      if( $(this).hasClass("header-main") ) {

        if (height > 0) {
            $(this).addClass("affixed");
        } else {
            $(this).removeClass("affixed");
        }
      }else{

        if (height > position.top) {
          $(this).addClass("affixed");
          $("body").addClass($(this).attr("id")+"-affixed");
        } else {
          $(this).removeClass("affixed");
          $("body").removeClass($(this).attr("id")+"-affixed");
        }
      }
    });
  }
  
  $("body").on("click", "[href=\"#hero-popup-form\"]", function(e) {
    $(".hubspot").show();
    $("body").addClass("lock-scroll");
    e.preventDefault();
  });

  $(".hubspot__container").on("click", function(e) {
    if (this === e.target) {
      $(".hubspot").hide();
      $("body").removeClass("lock-scroll");
    }    
  });

  return (
    <header className="section header-main affix">
        <div className="header-main__container container">
          <div className="header-main__logo-wrapper">        
            <a href="https://formationscorp.com/" className="header-main__logo">
              <img src={optionsData.acf.logo_dark.url} alt={optionsData.acf.logo_dark.alt} />
            </a>        
          </div>         

          <nav className="header-main__nav affix">
            <ul>
              {menuData.items.map((data, key) => {
                return (
                  <li key={key}>                
                    <a href={data.url}><span>{data.title}</span></a>
                  </li>                  
                );
              })}
            </ul>
          </nav>
        </div>
    </header>
  );
};