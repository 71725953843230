import React from "react";

export const Recommendations = ({
  recommendation,
  title,
  icon,
  icons,
  count,
  benefits,
  note,
  link,
  url,
}) => {
  const Benefits = () => {
    if (benefits) {
      return <h3 className="recommendation__benefits">{benefits}</h3>;
    } else {
      return null;
    }
  };

  const Note = () => {
    if (note) {
      return <span className="recommendation__benefits-note">{note}</span>;
    } else {
      return null;
    }
  };

  const Count = () => {
    if (count) {
      return <span className="recommendation__benefits-count">{count}</span>;
    } else {
      return null;
    }
  };

  const Link = () => {
    if (link) {
      return (
        <a href={url} className="recommendation__benefits-link">
          {link}
        </a>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="recommendation__item">
      <div className="recommendation__icon">
        <img src={icons} alt="icon" />
      </div>
      <div className="recommendation__text">
        <h3 className="recommendation__title">{title}</h3>        
        <div className="recommendation__descr" dangerouslySetInnerHTML={{__html: recommendation}} />        
        <Benefits />
        <Note />
        <Count />
        <Link />
      </div>
    </div>
  );
};
