export const baseReportData = {
  "acf": {
      "sidebar": {
          "sidebar_title": "What’s next?",
          "sidebar_items": [
              {
                  "text": "<p>Are you ready to take the next step? Join Formations to start managing your taxes and save thousands of dollars every year.</p>\n",
                  "button_text": "Start Saving Today",
                  "button_link": "https://get.formationscorp.com/apply-to-join"
              }
          ]
      },
      "recomendations": [
          {
              "image": "assets/uploads/2020/11/become.svg",
              "title": "Become an S-Corp",
              "text": "<p>There’s no better tax structure for self-employed businesses than an S-Corp, especially at your income level. As an S-Corp, you can enjoy all the benefits of a corporation without losing your independence as a self-employed business. You will also be able to pay yourself a salary and reduce your taxes. And unlike C-Corps, you will not have to pay corporate tax.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/incorporate.svg",
              "title": "Incorporate",
              "text": "<p>As a sole prop, you are unprotected and are putting yourself at risk. As a business owner, your first step is to incorporate and separate your personal and business affairs. An LLC will allow you to have limited liability protection and operate as a corporation rather than an individual. Also, you get to choose a cool company name!</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Liability protection and separation of your business and personal.",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/put.svg",
              "title": "Put profits first",
              "text": "<p>Your business is in good health, so it’s time to think about the concept of “Profit First.” Most self-employed individuals and small business owners think about expenses first and profit last, if at all. When money is generated, it first covers expenses, bills, and the cost of doing business, leaving a profit to be an afterthought. The “Profit First” concept flips this logic and creates a more realistic view of your business&#8217;s purpose—to provide you with financial well-being. If you assign a profit percentage to every revenue your business generates, even as small as 2%, you would be taking care of your financial well-being first. Only after this first step can you take care of everything else. Think of it as the airplane safety instructions—you need to put your mask on first before helping anyone else.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "A consistent, monthly base salary.",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/manage.svg",
              "title": "Manage your expense profile",
              "text": "<p>There’s a magic ratio of expenses, taxes, savings, and profit that businesses of your size need to optimize for. The first step is to ensure your business expenses are appropriate for your income. Since expenses are “tax-free,” you should be optimizing them regularly to achieve the right balance between expenses and income. Expensing too little means you are overpaying on your taxes and are missing opportunities to invest in your business, while expensing too much means you are overspending, or worse, overreporting.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": "$2,000 - $5,000"
          },
          {
              "image": "assets/uploads/2020/11/stop-1.svg",
              "title": "Stop spending time on your back-office",
              "text": "<p>We&#8217;re guessing you started your business to focus on your clients and your impact, not to spend half your days pouring over your books and stressing about taxes. Check out the following formula to understand how much back-office time you could instead be putting towards growing your business: <strong>Opportunity cost per hour = your annual income divided by 2000</strong> (for example, if you make $60K a year, you make about $30 per hour). Multiply the number of hours you spend per month on your business financials, and you will get your basic opportunity cost. Anything over those 10 hours is time best shifted to your core business and growth, or better yet &#8211; time to improve your well-being (family, friends, relaxation, hobbies).</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/start.svg",
              "title": "Start exploring growth strategies",
              "text": "<p>Your business seems to be in good health overall, which means that you have room to grow while your business is stable and healthy. This is the time to start considering growth strategies to take your business to the next level. Are you optimizing your financials based on your customer ROI? Are you investing enough in marketing to bring new business in at an affordable rate? Have you adjusted your expense profile to ensure long-term investment in growth and expansion? We recommend taking a close look at your business plan to determine which areas you can invest in to build a stronger, healthier business.</p>\n",
              "benefit_title": "",
              "benefit_text": "",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/save.svg",
              "title": "Save for retirement ",
              "text": "<p>You can save big on your taxes by investing in retirement benefits and flexible savings accounts, but even more important is the peace of mind of having money set aside for when you need it. The earlier you start saving &#8211;even if it’s just $100 a month &#8212; the better off you will be at retirement age. Becoming an S-Corp can help you incorporate the monthly benefit payments into your paycheck and reduce your tax liability.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/ensure.svg",
              "title": "Ensure your financial compliance",
              "text": "<p>Back-office expenses are an important business cost to account for. Most self-employed individuals try to manage the back office on their own to save money but end up paying a lot more in time, penalties, missed opportunities, and catch-up accounting. Your total cost of back-office management should be about 7% to 10% of your annual gross income. And don’t forget that these costs are deductible business expenses!</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Audit protection",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/audit.svg",
              "title": "Audit your back-office expenses",
              "text": "<p>Your total cost of back-office management, a deductible business expense, should be no more than 7%-10% of your annual gross income. If you pay more than that, you should audit your process and vendors as you might be paying more than what you need.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/take.svg",
              "title": "Take care of your personal health",
              "text": "<p>Uh oh, you’re not covered. Health insurance is one of the key benefits of W2 employees, but even as a self-employed, you can, and should, be covered. You can find coverage options and more info here: <a href=\"https://www.healthcare.gov/self-employed/coverage/\" target=\"_blank\" rel=\"noopener\">https://www.healthcare.gov/self-employed/coverage/</a></p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Peace of mind and potentially thousands of dollars in medical expenses. ",
              "benefit_amount": ""
          },
          {
              "image": "assets/uploads/2020/11/fsa.svg",
              "title": "Use FSA to reduce taxable income",
              "text": "<p>Every year, you can put aside up to $2,500 for vision and dental and another $5,000 for childcare (pre and after-school activities, summer camps, and childcare for kids younger than 5 years old). A total of $7,500 pre-taxed means about $2,250 in tax savings every year.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": "$2,250"
          },
          {
              "image": "assets/uploads/2020/11/hsa.svg",
              "title": "Use HSA to reduce taxable income",
              "text": "<p>You can use up to $7,000 in Health Savings Account (HSA) every year for health expenses like medicine, co-pay, and other medical expenses. Your HSA funds are deposited to an HSA bank account, giving you a debit card and allowing you to roll the remaining funds every year. Maximizing the HSA means up to $2,100 in tax savings.</p>\n",
              "benefit_title": "Your benefits:",
              "benefit_text": "Save up to",
              "benefit_amount": "$2,100"
          }
      ],
      "sidebar_posts": {
          "sidebar_items": [
              {
                  "image": "assets/uploads/2020/11/1-2.svg",
                  "title": "Beginners guide to running an S-Corp",
                  "title_link": "https://get.formationscorp.com/s-corp-guide-download",
                  "description": "Download our free, step-by-step guide."
              },
              {
                  "image": "assets/uploads/2020/11/2-2.svg",
                  "title": "5 reasons to be an S-Corp",
                  "title_link": "https://youtu.be/1d7EQYW_ktI",
                  "description": "Watch our short video explaining the 5 main reasons to become an S-Corp."
              }
          ]
      },
      "footer_bool": false
  }
};
