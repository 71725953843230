import React from "react";

export const ResultHeader = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date();
  let currentDate =
    monthNames[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear();

  return (
    <div className="result__header-text">
      <h2 className="result__title">Your personalized report is ready!</h2>
      <p className="result__date">As of {currentDate}</p>
    </div>
  );
};
