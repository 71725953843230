import React, { useEffect, useState } from "react";
import { Field } from "formik";

import wordpressService from "../../services/wordpressService";
import { baseStepsData } from "../../data/baseStepsData";
import icon from "../../images/icon.png";
import Popup from "reactjs-popup";

export default () => {
  const [stepsData, setStepsData] = useState(baseStepsData);

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("steps"));
    })();
  }, []);

  const SidebarExpertTip = () => {
    if (stepsData.acf.step[9].sidebar.sidebar_expert_tip) {
      return <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[9].sidebar.sidebar_expert_tip}} /> 
    } else {
      return null;
    }
  };

  const SidebarLink = () => {
    if (stepsData.acf.step[9].sidebar.sidebar_link.title) {
      return (
        <a
          href={stepsData.acf.step[9].sidebar.sidebar_link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="sidebar__link"
        >
          {stepsData.acf.step[9].sidebar.sidebar_link.title}
        </a>
      );
    } else {
      return null;
    }
  };

  const DisabledBtn = () => {
    /* eslint-disable no-unused-expressions */
    document.getElementById("taxes").value = document.getElementById("taxes").value.replace(/[^\d]/g, '');
    if (document.getElementById("taxes").value === "") {
      document.getElementById("result")?.classList.add("button-disabled");
    } else {
      document.getElementById("result")?.classList.remove("button-disabled");
    }
  }

  if (document.getElementById("taxes")) {
    DisabledBtn();
  }

  return (    
    <div className="text">
      <h2 className="text__title">
        How much did you pay in taxes last year?
      </h2>
      <Popup
        trigger={
          <div className="text__link text__link-mobile">
            <img src={icon} alt="sidebar" />
            What does this mean?
          </div>
        }            
        modal
        className="sidebar__popup"
      >
        {(close) => (
          <div className="sidebar__inner sidebar__inner-mobile">
            <div className="sidebar__close" onClick={close}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#404040" fillOpacity="0.87"/>
              </svg>
            </div>
            <div className="sidebar__block-text">
              <h3 className="sidebar__title">
                {stepsData.acf.step[9].sidebar.sidebar_title}
              </h3>
              <div dangerouslySetInnerHTML={{__html: stepsData.acf.step[9].sidebar.sidebar_description}} />
            </div>
            <div className="sidebar__block-link">
              <div className="sidebar__subtitle">
                {stepsData.acf.step[9].sidebar.sidebar_title_2}
              </div>
              <SidebarExpertTip />
              <SidebarLink />
            </div>
          </div>
        )}
      </Popup>
      <div className="form-field">
        <Field 
          id={"taxes"}
          name={"taxes"} 
          type={"text"} 
          min={0} 
          max={50000}
          onInput = {DisabledBtn}
        />            
      </div>
    </div>      
  );
};
