import React, {useCallback, useEffect, useMemo, useState} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import Cookies from "universal-cookie";
import { css, styled, setup } from "goober";

import check from "../images/svg/check.svg";
import user_plus from "../images/user-plus.png";
import handshake_alt from "../images/handshake-alt.png";
import hand_holding_heart from "../images/hand-holding-heart.png";
import { Sidebar } from "../components/assets/Sidebar";
import { dataLayerTagManager } from "./tagManager";
import FormReport from "../components/form";
import Popup from "reactjs-popup";
import { isSalesDemoMode } from '../helpers/queryParam';

setup(React.createElement);

const Ol = styled("ol")`
  margin-bottom: 45px;
  padding-right: 65px;
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1092px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const LiClass = (props) => css`
  position: relative;
  width: 100%;
  font-family: "Surt-Medium";

  color: ${props.state === "done" ? "#00D0C4" : "#BDBDBD"};
  border-top: 2px solid ${props.state === "done" ? "#00D0C4" : "#BDBDBD"};

  &:before {
    position: relative;
    float: left;
    top: -10px;

    content: " ";
    background-color: white;
    width: 1.2em;
    border-radius: 45px;
    border: 2px solid;
    border-color: ${props.state === "todo" ? "#BDBDBD" : "#00D0C4"};
    height: 20px;
    width: 20px;
    box-sizing: border-box;
  }

  &:hover,
  &::before {
    color: #0fa0ce;
  }

  &:after {
    ${props.state === "done" ? 'content: " ";' : "content: none"};
    background-image: url("${check}");
    background-repeat: no-repeat;
    background-size: initial;
    background-position: center;
    transition: all 0.3s ease;
    position: absolute;
    left: 3px;
    top: -4px;
    width: 13px;
    height: 9px;
  }

  span {
    font-weight: ${props.state === "doing" ? "bold" : "normal"};
    color: ${props.state !== "todo" ? "#00D0C4" : "#BDBDBD"};
    position: absolute;
    bottom: -7px;
    white-space: nowrap;
    font-size: 0.857rem;
    font-family: "Surt-Medium", sans-serif;
    line-height: 1.25;
    text-transform: capitalize;
    transform: translate(calc(-50% + 10px), 0);
    left: 0;

    @media (max-width: 808px) {
      display: ${props.state === "doing" ? "block" : "none"};
    }
  }

  &:last-child {
    width: auto;
  }
`;
const getTopNavStyles = (indx, length) => {
  let styles = [];
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push("done");
    } else if (i === indx) {
      styles.push("doing");
    } else {
      styles.push("todo");
    }
  }
  return styles;
};

const getButtonsState = (indx, length) => {
  if (indx !== 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
    };
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
    };
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false,
    };
  }
};

const stepMap = {
  "#business-entity": 0,
  "#income": 1,
  "#expense": 2,
  "#benefits": 3,
  "#benefits-amount": 4,
  "#health-coverage": 5,
  "#health-coverage-amount": 6,
  "#back-office": 7,
  "#vendor": 8,
  "#tax": 9,
};
const getStepByHash = (hash, search) => {
  hash = hash.replace(search, '');
  return stepMap[hash] || 0;
};
const getHashByStep = (inx, search) => {
  return (
    search+(Object.entries(stepMap).find(([, step]) => {
      return inx === step;
    })[0] || undefined)
  );
};

const isEmpty = (data) => {
  return data == null || data.length === 0
}
const getStepFromData = (data) => {
  const dataCheck = [
    'legal-entity',
    'annual-income',
    'business-expenses',
    'benefits',
    'annually-benefits',
    'medical-benefits',
    'health-coverage',
    'spend-time',
    'management-costs',
    'taxes',
  ];
  if (data == null) return 0;
  return dataCheck.findIndex(item => isEmpty(data[item]));
}

const getValues = (values, step) => {
  const entityHasValue = !(values['legal-entity'] == null || values['legal-entity'].length === 0);
  if (step === 0 || entityHasValue) return values
  return {...values, 'legal-entity': "Sole proprietorship"}
}

export default function MultiStep(props) {
  const cookies = new Cookies();

  let showNav = true;
  const { search, hash } = useLocation();
  const { push } = useHistory();

  if (props.showNavigation) showNav = props.showNavigation;

  const step = useMemo(() => getStepByHash(hash, search), [hash, search]);
  const compState = step;
  const setComp = useCallback((indx) => push(getHashByStep(indx, search)), [push, search]);
  
  const [stylesState, setStyles] = useState(
    getTopNavStyles(step, props.steps.length)
  );
  const [buttonsState, setButtons] = useState(
    getButtonsState(step, props.steps.length)
  );

  const setStepState = useCallback((indx) => {
    setStyles(getTopNavStyles(indx, props.steps.length));
    setComp(indx < props.steps.length ? indx : compState);
    setButtons(getButtonsState(indx, props.steps.length));
  }, [compState, props.steps.length, setComp]);

  useEffect(() => {
    const lastStepHasData = getStepFromData(cookies.get("formationsCalculator"))
    if (step > lastStepHasData) {
      setStepState(lastStepHasData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const next = () => setStepState(compState + 1);
  const nextTwo = () => setStepState(compState + 2);

  const previous = () =>
    setStepState(compState > 0 ? compState - 1 : compState);
  const previousTwo = () =>
    setStepState(compState > 0 ? compState - 2 : compState);

  const handleKeyDown = (evt) => (evt.which === 13 ? next() : {});

  const renderSteps = () =>
    props.steps.map((s, i) => {
      if (compState === 4) {
        if (i !== 4 && i !== 6 && i !== 3) {
          return (
            <li
              className={LiClass({ state: stylesState[i] })}
              key={i}
              value={i}
            >
              <span>{s.name}</span>
            </li>
          );
        } else if (i === 3) {
          return (
            <li className={LiClass({ state: "doing" })} key={i} value={i}>
              <span>{s.name}</span>
            </li>
          );
        } else return null;
      } else if (compState === 6) {
        if (i !== 4 && i !== 6 && i !== 5) {
          return (
            <li
              className={LiClass({ state: stylesState[i] })}
              key={i}
              value={i}
            >
              <span>{s.name}</span>
            </li>
          );
        } else if (i === 5) {
          return (
            <li className={LiClass({ state: "doing" })} key={i} value={i}>
              <span>{s.name}</span>
            </li>
          );
        } else return null;
      } else if (i !== 4 && i !== 6) {
        return (
          <li className={LiClass({ state: stylesState[i] })} key={i} value={i}>
            <span>{s.name}</span>
          </li>
        );
      } else return null;
    });

    function renderFormButton(show) {
      if (show) {
        const isSalesDemo = isSalesDemoMode();
        return (
          isSalesDemo ? (<Link to={`/report${search}`} className="button button-yellow">
          SEE RESULTS
        </Link>) : (
          <Popup
            trigger={
              <button
                className="button button-yellow button-disabled"
                id="result"
              >
                SEE RESULTS
              </button>
            }
            position="center center"
            modal
          >
            {(close) => <FormReport closeFunction={close} />}
          </Popup>)
        );
      }
    }

  function renderPreviousButton(show) {
    if (show) {
      return (
        <Link to={`/${search}`} className="button button-white">
          Previous
        </Link>
      );
    }
  }

  function getStateNext(compStateNext, values) {
    if (compStateNext === 3 && values["benefits"].toString() === "") {
      return nextTwo;
    } else if (
      compStateNext === 5 &&
      values["medical-benefits"].toString() === ""
    ) {
      return nextTwo;
    } else {
      return next;
    }
  }

  function getStatePrevious(compStatePrev, values) {
    if (compStatePrev === 5 && values["benefits"].toString() === "") {
      return previousTwo;
    } else if (
      compStatePrev === 7 &&
      values["medical-benefits"].toString() === ""
    ) {
      return previousTwo;
    } else {
      return previous;
    }
  }

  function updateValueInsurance(values) {
    values["value-insurance"] = true;
  }

  function showBenefitsLink(compStateLinks, values) {
    if (compStateLinks === 0) {
      return (<div
        className="text__link"
        onClick={() => {
          getStateNext(compStateLinks, values)();
        }}
      >
        <img src={user_plus} alt="entity" />I'm not sure
      </div>)
    } else if (compStateLinks === 3) {
      return (
        <div
          className="text__link"
          onClick={getStateNext(compStateLinks, values)}
        >
          <img src={user_plus} alt="benefit" />I have no benefits
        </div>
      );
    } else if (compStateLinks === 5) {
      return (
        <>
          <div
            className="text__link"
            onClick={() => {
              updateValueInsurance(values);
              getStateNext(compStateLinks, values)();
            }}
          >
            <img src={handshake_alt} alt="benefit" />
            I’m using my partner’s health insurance
          </div>

          <div
            className="text__link"
            onClick={getStateNext(compStateLinks, values)}
          >
            <img src={hand_holding_heart} alt="benefit" />I don’t have health
            insurance
          </div>
        </>
      );
    }
  }

  const renderNav = (show, compStateNav, values) =>
    show && (
      <>
        {showBenefitsLink(compStateNav, values)}

        <div className="nav-buttons">
          <div className="button-block">
            {renderPreviousButton(!buttonsState.showPreviousBtn)}

            <button
              type="button"
              className="button button-white"
              style={
                buttonsState.showPreviousBtn
                  ? props.prevStyle
                  : { display: "none" }
              }
              onClick={getStatePrevious(compStateNav, values)}
              name="previous"
            >
              Previous
            </button>

            <button
              type="button"
              className="button button-yellow button-disabled"
              style={
                buttonsState.showNextBtn ? props.nextStyle : { display: "none" }
              }
              onClick={() => {
                if (
                  compStateNav === 0 &&
                  (document.getElementById("myRadio1").checked ||
                    document.getElementById("myRadio2").checked ||
                    document.getElementById("myRadio3").checked ||
                    document.getElementById("myRadio4").checked)
                ) {
                  getStateNext(compStateNav, values)();
                } else if (
                  compStateNav === 3 &&
                  (document.getElementById("myCheckbox1").checked ||
                    document.getElementById("myCheckbox2").checked ||
                    document.getElementById("myCheckbox3").checked)
                ) {
                  getStateNext(compStateNav, values)();
                } else if (
                  compStateNav === 5 &&
                  (document.getElementById("myCheckbox1").checked ||
                    document.getElementById("myCheckbox2").checked ||
                    document.getElementById("myCheckbox3").checked)
                ) {
                  getStateNext(compStateNav, values)();
                } else if (
                  compStateNav !== 0 &&
                  compStateNav !== 3 &&
                  compStateNav !== 5
                ) {
                  getStateNext(compStateNav, values)();
                }

                dataLayerTagManager(["step", compStateNav]);
              }}
              name="next"
            >
              Next
            </button>
            {renderFormButton(!buttonsState.showNextBtn)}
          </div>
        </div>
      </>
    );

  const formData = {
    "legal-entity": [],
    "annual-income": "",
    "business-expenses": "",
    benefits: [],
    "annually-benefits": "",
    "medical-benefits": [],
    "health-coverage": "",
    "spend-time": "",
    "management-costs": "",
    taxes: "",
    "value-insurance": false,
    "first-name": "",
    "last-name": "",
    "business-name": "",
    email: "",
    "select-industry": "",
    "select-state": "",
    "select-kids": "",
  };

  const [, setStep] = useState(1);
  const handleSubmit = (values) => {
    setStep((step) => step + 1)
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    return errors;
  };

  return (
    <div onKeyDown={handleKeyDown} style={{ width: "100%" }}>
      <Ol className="content">{renderSteps()}</Ol>

      <Formik
        enableReinitialize
        initialValues={{ ...formData }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, errors, touched }) => (
          <Form>
            {cookies.set("formationsCalculator", getValues(values, compState), {
              path: "/",
              sameSite: "strict",
            })}

            <div className="row">
              <div className="content" id={`step${compState + 1}`}>
                {props.steps[compState].component}
                {renderNav(showNav, compState, values)}
              </div>
              <Sidebar step={compState} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
