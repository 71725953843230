import Cookies from "universal-cookie";
import analysisService from "./analysisService";

/*
 * Hubspot integration, documentation: https://legacydocs.hubspot.com/docs/methods/forms/submit_form.
 */
class HubspotService {
  constructor() {
    // View form: https://share.hsforms.com/1shRHMtjPScSEKrNyL2c5vA3y9mn.
    this.portalId = "6637631";
    this.formId = "cce48b49-123e-4ac9-9d9f-d3e4f059b91c";
  }

  getAnnualIncome(data) {
    let results = {
      40000: "Less than $40K",
      76600: "$40K-$80K",
      113200: "$80K-$150K",
      150000: "Over $150K",
      default: "",
    };
    return results[data] || results["default"];
  }

  getBusinessExpenses(data) {
    let results = {
      10000: "Less than $10K",
      19000: "$10K-$20K",
      28000: "$20K-$30K",
      37000: "$30K-$40K",
      46000: "$40K-$50K",
      55000: "$50K-$60K",
      64000: "$60K-$70K",
      73000: "$70K-$80K",
      82000: "$80K-$90K",
      91000: "$90K-$100K",
      100000: "Over $100K",
      default: "",
    };
    return results[data] || results["default"];
  }

  getAnnuallyBenefits(data) {
    let results = {
      5000: "Less than $500",
      15000: "$500-$1,000",
      20000: "$1,000-$2,000",
      30000: "Over $2,000",
      40000: "I'm not sure",
      default: "",
    };
    return results[data] || results["default"];
  }

  getHealthCoverage(data) {
    let results = {
      2000: "Less than $2,000",
      4000: "$2,000-$3,500",
      6000: "$3,500-$5,000",
      8000: "$5,000-$8,000",
      10000: "$8,000-$12,000",
      12000: "Over $12,000",
      default: "",
    };
    return results[data] || results["default"];
  }

  getSpendTime(data) {
    let results = {
      10: "Less than 10 hours per month (about 2 hours per week)",
      20: "10 hours - 20 hours per month (about half a day per week)",
      30: "20 hours - 40 hours per month (about a full day every week)",
      40: "Over 40 hours per month (more than a day per week)",
      default: "",
    };
    return results[data] || results["default"];
  }

  getManagementCosts(data) {
    let results = {
      0: "$0 - I do it all by myself",
      1250: "Less than $500 per year",
      2500: "$500-$1,500 per year",
      3750: "$1,500-$3,500 per year",
      5000: "$3,500-$5,000 per year",
      6250: "$5,000-$7,500 per year",
      7500: "Over $7500 per year",
      default: "",
    };
    return results[data] || results["default"];
  }

  formatHubspotPost(name, data) {
    if (name === "annual-income") {
      return this.getAnnualIncome(data);
    } else if (name === "business-expenses") {
      return this.getBusinessExpenses(data);
    } else if (name === "annually-benefits") {
      return this.getAnnuallyBenefits(data);
    } else if (name === "health-coverage") {
      return this.getHealthCoverage(data);
    } else if (name === "spend-time") {
      return this.getSpendTime(data);
    } else if (name === "management-costs") {
      return this.getManagementCosts(data);
    } else if (name === "taxes") {
      return data;
    }
  }

  hubspotPost(reportData, valueBenefits, valueHealthCoverage) {
    // Create the new request.
    var xhr = new XMLHttpRequest();
    var url = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`;

    // Get data.
    const cookies = new Cookies();
    const cookiesRes = cookies.get("formationsCalculator");
    const hubspotUserToken = cookies.get("hubspotutk");
    let analysis = analysisService.analysis(reportData);

    if (cookiesRes["benefits"].length !== 0) {
      valueBenefits = cookiesRes["benefits"].join(";");
    } else {
      valueBenefits = "I have no benefits";
    }

    if (cookiesRes["medical-benefits"].length !== 0) {
      valueHealthCoverage = cookiesRes["medical-benefits"].join(";");
    } else if (cookiesRes["value-insurance"] === true) {
      valueHealthCoverage =
        "I'm on someone else's insurance (partner, parents)";
    } else {
      valueHealthCoverage = "I don't have health insurance";
    }

    // View fields: https://app.hubspot.com/forms/6637631/editor/b2144732-d8cf-49c4-842a-b3722f6739bc/edit/form.
    var data = {
      // Date.
      submittedAt: Date.now(),
      // Form data.
      fields: [
        {
          name: "email",
          value: cookiesRes["email"],
        },
        {
          name: "firstname",
          value: cookiesRes["first-name"],
        },
        {
          name: "lastname",
          value: cookiesRes["last-name"],
        },
        {
          name: "do_you_have_full_time_employees___new_100y_",
          value: cookiesRes["select-employees"],
        },
        {
          name: "industry_verticals_",
          value: cookiesRes["select-industry"],
        },
        {
          name: "state_province",
          value: cookiesRes["select-state"],
        },
        {
          name: "calculator_health_score",
          value: analysis.point,
        },
        {
          name:
            "calculator_how_much_are_you_paying_annually_to_manage_your_benefits_",
          value: this.formatHubspotPost(
            "annually-benefits",
            cookiesRes["annually-benefits"].toString()
          ),
        },
        {
          name: "calculator_what_type_of_benefits_do_you_have_today_",
          value: valueBenefits,
        },
        {
          name:
            "calculator_what_is_your_annual_total_cost_for_managing_your_business_financials_including_bookkeepi",
          value: this.formatHubspotPost(
            "management-costs",
            cookiesRes["management-costs"].toString()
          ),
        },
        {
          name:
            "calculator_how_much_time_do_you_spend_managing_your_business_financials_and_back_office_",
          value: this.formatHubspotPost(
            "spend-time",
            cookiesRes["spend-time"].toString()
          ),
        },
        {
          name: "calculator_how_much_did_you_pay_in_taxes_last_year_",
          value: this.formatHubspotPost(
            "taxes",
            cookiesRes["taxes"].toString()
          ),
        },
        {
          name:
            "calculator_how_much_are_you_paying_annually_for_your_health_coverage_",
          value: this.formatHubspotPost(
            "health-coverage",
            cookiesRes["health-coverage"].toString()
          ),
        },
        {
          name: "calculator_what_type_of_health_coverage_do_you_have_",
          value: valueHealthCoverage,
        },
        {
          name:
            "calculator_how_much_money_will_your_business_generate_this_year_",
          value: this.formatHubspotPost(
            "annual-income",
            cookiesRes["annual-income"].toString()
          ),
        },
        {
          name: "calculator_what_is_your_business_legal_entity_",
          value: cookiesRes["legal-entity"].toString(),
        },
        {
          name: "calculator_what_are_your_projected_annual_business_expenses_",
          value: this.formatHubspotPost(
            "business-expenses",
            cookiesRes["business-expenses"].toString()
          ),
        },
      ],
      ...(hubspotUserToken && { context: {
        hutk: hubspotUserToken, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      }}),
    };

    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      const validStatus = [200, 400, 403, 404];
      if (xhr.readyState === 4 && validStatus.includes[xhr.status]) {
        console.log(xhr.responseText);
      }
    };

    // Sends the request.
    // View submissions: https://app.hubspot.com/forms/6637631/b2144732-d8cf-49c4-842a-b3722f6739bc/submissions.
    xhr.send(final_data);
  }
}

export default new HubspotService();
