import React, { useEffect, useState } from "react";
import wordpressService from "../../services/wordpressService";
import { baseReportData } from "../../data/baseReportData";

export const ReportBlock = () => {
  const [reportData, setReportData] = useState(baseReportData);

  useEffect(() => {
    (async () => {
      setReportData(await wordpressService.getData("report"));
    })();
  }, []);

  return (
    <>
      <div className="sidebar__report-block-text">
        <h3 className="sidebar__title sidebar__report-title">
          {reportData.acf.sidebar.sidebar_title}
        </h3>
        <div dangerouslySetInnerHTML={{__html: reportData.acf.sidebar.sidebar_items[0].text}} />
        <div className="sidebar__button">
          <a
            href={`${reportData.acf.sidebar.sidebar_items[0].button_link}`}
            className="button button-yellow sidebar__button-send"
            target="_blank"
            rel="noopener noreferrer"
          >
            {reportData.acf.sidebar.sidebar_items[0].button_text}
          </a>
        </div>
      </div>
    </>
  );
};
