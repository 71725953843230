import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { baseHomeData } from "../data/baseHomeData";
import wordpressService from "../services/wordpressService";

export default () => {

  const [homeData, setHomeData] = useState(baseHomeData);
  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      setHomeData(await wordpressService.getData("home"));
    })();
  }, []);  
  const isEmbed = search.includes('embed');
  return (
    <div className={isEmbed ? "full-container": "container"}>
      <div className={`first-page ${isEmbed ? "full-main": "main"}`}>
        <div className={isEmbed ? "full-content": "content"}>
          <h2 className="first-page__title">{homeData.acf.title}</h2>
          <div className="first-page__descr" dangerouslySetInnerHTML={{__html: homeData.acf.description}} />          
          <div className="first-page__image">
            <img src={homeData.acf.image.url} alt={homeData.acf.image.alt} width="auto" />
          </div>
          <div className="first-page__button">
            <Link to={`/steps${search}`} className="button button-yellow">
              {homeData.acf.button_text}
            </Link>
          </div>
        </div>
      </div>
    </div>    
  );
};